.modal_content {
  border-radius: 2rem;
  overflow: hidden;
  padding: 2rem 3rem;
}
.modal_title {
  font-size: 4.5rem;
  font-weight: 300;
  color: #1a4e74;
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
/* Modal Body Design */

.wrapper {
  max-height: 43rem;
  overflow: auto;
  padding: 0 1rem 1rem 0;
}
.notification {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 2rem;
  background: #f5fcff;
  padding: 1rem 2rem 1rem;
  color: #1a4e74;
  font-weight: 300;
}
.notification:not(:last-child) {
  margin-bottom: 2rem;
}
.notification_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add_tribute {
  font-size: 1.5rem;
  color: #1e272d;
  border: none;
  background-color: transparent;
}
.add_tribute span {
  font-weight: 500;
}
.notification_creator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
}
.creator_img_container {
  position: relative;
}
.cf_count {
  width: 2.9rem;
  height: 2.9rem;
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.cf_count_flower {
  background-image: url('https://res.cloudinary.com/lifecloud-qr/image/upload/v1672830376/Rectangle_60_ggkcl7.png');
}
.cf_count_candle {
  background-image: url('https://res.cloudinary.com/lifecloud-qr/image/upload/v1673871864/roundCandle_lgthl1.png');
  left: 2.15rem;
}
.count {
  font-size: 1.5rem;
  font-weight: 500;
}
.creator_img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}
.creator_info h6 {
  font-size: 2.2rem;
  font-weight: 500;
  direction: rtl;
  text-align: right;
}
.creator_info p {
  font-size: 2rem;
  direction: rtl;
  text-align: right;
}
.creator_info span {
  font-size: 1.8rem;
  direction: rtl;
  text-align: right;
  display: block;
}
.comment_content h5 {
  font-size: 1.8rem;
  font-weight: 500;
  direction: rtl;
  text-align: right;
}
.comment_text {
  font-size: 1.5rem;
}
.commenter {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.add_tribute_2 {
  background-color: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  direction: rtl;
  padding: 1.5rem;
  margin-top: 1.5rem;
  font-size: 1.2rem;
  color: #1e272d;
}
@media only screen and (max-width: 576px) {
  .notification_container {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
/* ScrollBar Styling */
.wrapper::-webkit-scrollbar {
  width: 0.3rem;
}

.wrapper::-webkit-scrollbar-track {
  background: #dcecf4;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #6097bf;
  border-radius: 2rem;
  background-clip: content-box;
  transition: all 0.3 ease-in;
}
