.search_title {
  font-weight: 300;
  font-size: 3.5rem;
  color: #1a4e74;
  margin-bottom: 5rem;
}
.search_subtitle {
  color: #1a4e74;
  font-size: 2.5rem;
  font-weight: 300;
  margin-top: 2rem;
}
.pill_box {
  background: #1a4e74;
  border-radius: 3rem;
  padding: 1rem 3rem;
  font-weight: 400;
  font-size: 3rem;
  color: #fff;
}
.pill_box:not(:last-child) {
  margin-bottom: 2rem;
}
.pill_box:hover {
  color: #fff;
}

.pill_box img {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  cursor: pointer;
}

.go_back_container {
  margin-top: 6rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.go_back_container img {
  margin-top: 0.8rem;
  cursor: pointer;
}
.go_back_container p {
  font-size: 3rem;
  color: #1a4e74;
  font-weight: 300;
  cursor: pointer;
}
.no_result {
  font-weight: 300;
  font-size: 4.5rem;
  color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .search_subtitle {
    font-size: 2rem;
  }
  .pill_box {
    padding: 0.8rem 2rem;
    border-radius: 2rem;
    font-size: 2.2rem;
  }
  .pill_box img {
    width: 4.5rem;
    height: 4.5rem;
  }
  .no_result {
    font-weight: 300;
    font-size: 3.2rem;
    color: #1a4e74;
  }
}
@media screen and (max-width: 768px) {
  .go_back_container {
    gap: 1rem;
  }
  .go_back_container p {
    font-size: 2.5rem;
  }
}
