/* Toastify Creator */
.Toastify__toast-body > div:last-child {
  font-size: 1.5rem;
  font-family: inherit;
}

/* Swal2 */
.swal2-container.swal2-center > .swal2-popup {
  font-size: 1.6rem;
}
/* Swiper */
.swiper {
  height: 100%;
  width: 100%;
}
.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #abc9db;
}

body .swiper-button-next,
body .swiper-button-prev {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 5rem;
}
body .slick-prev,
body .slick-next {
  width: 54px;
  height: auto;
  z-index: 2;
}
body .slick-prev {
  left: -54px;
}
body .slick-next {
  right: -54px;
}

.swiper-button-next {
  background-image: url('./assets/home-img/arrow-right.png');
}
.swiper-button-prev {
  background-image: url('./assets/home-img/arrow-left.png');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}
body .swiper-pagination-bullet {
  background: #1a4e74 !important;
  height: 1.6rem;
  width: 1.6rem;
}

body .swiper-pagination-bullet-active {
  background: #abc9db;
}

.reviewSwiper .swiper-pagination-bullet,
.swiper-wrapper .swiper-pagination-bullet {
  height: 1.6rem;
  width: 1.6rem;
}
.qrSwiper .swiper-pagination-bullet {
  background: #1a4e74 !important;
  height: 1.2rem;
  width: 1.2rem;
}
.reviewSwiper .swiper-wrapper,
.demoSwiper .swiper-wrapper {
  padding-bottom: 5rem;
}

/* Form Error Message CSS */
.page_title {
  font-size: 7.5rem;
  text-align: center;
  color: #1a4e74;
  margin-bottom: 5rem;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  .page_title {
    font-size: 5.2rem;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 576px) {
  .page_title {
    font-size: 4.2rem;
  }
}
.section_title {
  font-size: 3.6rem;
  font-weight: 600;
  color: #1a4e74;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .section_title {
    font-size: 2.6rem;
  }
}
.profile_title {
  font-weight: 300;
  font-size: 4.5rem;
  text-align: center;
  color: #1a4e74;
  margin-bottom: 5rem;
}

.profile_button_group {
  margin-top: 7.5rem;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .profile_title {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
  .profile_button_group {
    margin-top: 5rem;
  }
}
.error {
  color: crimson;
  font-size: 1.3rem;
  margin-top: 0.6rem;
  display: block;
  font-weight: 600;
}
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
img,
video {
  object-fit: cover;
}
.not_found {
  font-size: 2.5rem;
  font-weight: 300;
  text-align: center;
  color: #6097bf;
  z-index: 2;
  position: relative;
}

a.active {
  color: #1a4e74 !important;
}
@media screen and (max-width: 768px) {
  body .swiper-button-next,
  body .swiper-button-prev,
  body .slick-prev,
  body .slick-next {
    width: 2.5rem;
  }
  body .slick-prev {
    left: -2.5rem;
  }
  body .slick-next {
    right: -2.5rem;
  }
  body .swiper-pagination-bullet {
    height: 0.8rem;
    width: 0.8rem;
  }
}
.auth-modal .modal-content {
  text-align: center;
  border: none;
  background-color: transparent;
  border-radius: 2rem;
  overflow: hidden;
}
.qr_modal .modal-content {
  border-radius: 2rem;
  overflow: hidden;
}
