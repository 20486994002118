.modal_header {
  background: #1a4e74;
}
.modal_title {
  font-size: 4rem;
  font-weight: 200;
  color: #fff;
}
.reset_btn {
  background: #fff;
  border: 1px solid #1a4e74;
  font-size: 1.2rem;
  padding: 0.2rem 1.5rem;
  border-radius: 2rem;
  color: #1a4e74;
  font-weight: 400;
  transition: 0.3s all ease-in;
}
.reset_btn:hover {
  background-color: #1a4e74;
  color: #fff;
  border: 1px solid #fff;
}
.modal_sub_title {
  font-size: 1.8rem;
  font-weight: 200;
  color: #1a4e74;
  text-align: center;
}
.hr {
  width: 90%;
  color: #1a4e74;
  margin: 1rem auto;
}
.modal_body {
  padding: 1rem 2.5rem 2.5rem;
  position: relative;
}
.modal_body .input_container {
  margin-bottom: 2.5rem;
}
.modal_body input,
.file_label {
  font-weight: 200;
  text-align: center;
  font-size: 2rem;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 1rem;
  direction: rtl;
}
.modal_body input::placeholder {
  color: #1a4e74;
}
.modal_body input[type='date']::-webkit-calendar-picker-indicator,
.modal_body input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}
.qr_img {
  width: 100%;
  height: 12rem;
  border-radius: 3rem;
}
.qr_img_text {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(70, 120, 155, 0.5);
  border-radius: 3rem;
  display: block;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
  font-weight: 200;
}
.qr_order {
  gap: 2rem;
}
.qr_order p {
  font-size: 1.6rem;
  color: #1a4e74;
  font-weight: 300;
}
.qr_order img {
  width: 3.5rem;
  height: 3.5rem;
}
/* .checkbox {
  background: #1A4E74;
} */
.checkbox:checked {
  background-color: #1a4e74;
  border-color: #1a4e74;
}
.button_container button {
  width: 100%;
  padding: 1rem 0;
  background: #1a4e74;
  border-radius: 10px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 200;
  border: 1px solid #1a4e74;
  transition: 0.3s all ease-in;
}
.button_container button:hover {
  background: #fff;
  color: #1a4e74;
}
.cloud_img {
  position: absolute;
  max-width: 12rem;
  width: 100%;
  right: 0;
  top: 47%;
}
@media screen and (max-width: 768px) {
  .modal_title {
    font-size: 3rem;
  }
  .modal_sub_title {
    font-size: 1.4rem;
  }
  .cloud_img {
    display: none;
  }
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
.file_label {
  color: #1a4e74;
  width: 100%;
  cursor: pointer;
}
.modal_body input[type='file'] {
  display: none;
}

/* Media Share Styling */
.share_container {
  border-radius: 2rem;
  max-width: 35rem;
  width: 100%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background: #fff;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}
.share_container h3 {
  font-size: 2rem;
  background: #1a4e74;
  border-radius: 2rem 2rem 0 0;
  padding: 1rem 1.5rem;
  font-weight: 400;
  font-size: 2.4rem;
  color: #fff;
}
.share {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem 3rem;
}
.share_item {
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem 1.5rem;
  background: #fff;
  border: none;
}
.share_item img {
  width: 7rem;
  height: 7rem;
}
.share_item p {
  font-weight: 400;
  font-size: 2.4rem;
  color: #000000;
}
.share_footer p {
  font-weight: 300;
  font-size: 1.3rem;
  text-align: right;
  direction: rtl;
}
.share_footer {
  color: #1a4e74;
  padding: 0 1.5rem 1.5rem;
}
/* Preview */
.preview_container {
  z-index: 3;
  max-width: 50rem;
}
.preview_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.preview_container img {
  max-height: 38rem;
}
.preview_container .button_container {
  padding: 2rem 3rem;
}
.preview_container .share {
  padding: 0.5rem 3rem;
}
