.search_results {
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 5;
  padding: 0 2.5rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.search_results .search_result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;
}
.search_result:not(:last-child) {
  border-bottom: 1px solid #6097bf;
}
.search_result img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}
.search_result p,
.no_results {
  color: #6097bf;
  font-size: 1.6rem;
  font-weight: 500;
}
.no_results {
  text-align: center;
  padding: 0.8rem 0;
}
.view_all {
  color: #6097bf;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  display: block;
  padding: 1.5rem 0;
  transition: all 0.3s ease;
}
.view_all:hover {
  color: #1a4e74;
}
