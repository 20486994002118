.gallery_item .media {
  border-radius: 7rem;
  height: 25rem;
  width: 100%;
  overflow: hidden;
}
/* .gallery_grid {
  border-top: 2px solid #dcecf4;
} */
@media screen and (max-width: 1400px) {
  .gallery_item .media {
    height: 25rem;
    border-radius: 7rem;
  }
}
@media screen and (max-width: 992px) {
  .gallery_item .media {
    height: 18rem;
    border-radius: 6rem;
  }
}
@media screen and (max-width: 768px) {
  .gallery_grid {
    padding: 2rem 0;
  }
  .gallery_grid h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .gallery_grid button {
    font-size: 1.6rem;
    margin-top: 0.5rem;
  }
  .gallery_item {
    padding: 0 1.2rem;
    height: 100%;
  }

  .gallery_item .media {
    height: 16rem;
    border-radius: 5.5rem;
  }
}
@media screen and (max-width: 576px) {
  .gallery_item .media {
    height: 14rem;
    border-radius: 4.5rem;
  }
}
