/* Input Element CSS */
/*.input_container {*/
/*  margin-bottom: 3rem;*/
/*}*/
.input {
  width: 100%;
  text-align: center;
  border-radius: 1rem;
  border: 1px solid #fff;
  font-size: 2.2rem;
  font-weight: 300;
  font-family: inherit;
  color: #1a4e74;
  direction: rtl;
}
/* Changed here */
.input::placeholder,
.input:focus {
  color: #abc9db;
  text-align: center;
}

.input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

/* Input (type:radio) Element CSS */
.radio_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}
.radio_input {
  background: #fff;
  width: 5rem;
  height: 5rem;
  color: #6097bf;
  text-align: center;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 2.4rem;
  position: relative;
  overflow: hidden;
}
.radio {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.radio_label {
  font-weight: 400;
  font-size: 2.4rem;
  color: #1a4e74;
}
.radio:checked + label {
  background-color: #6097bf;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Form Bottom text CSS */
.form_footer_text {
  font-weight: 600;
  font-size: 2rem;
  color: #6097bf;
  text-decoration: none;
  display: inline-block;
}
.form_footer_text:hover {
  color: #6097bf;
}
.check_box {
  height: 2rem;
  width: 2rem;
  border-radius: 0.6rem !important;
  border: none;
}
.fancy_bar {
  font-size: 2rem;
  color: #6097bf;
  margin: 0 1.5rem;
}

.arrow {
  width: 12rem;
  margin: 0 auto;
  cursor: pointer;
}
.terms_checkbox {
  gap: 2.2rem;
}

.textarea {
  resize: none;
  height: 12rem;
  direction: rtl;
}
.submit_btn {
  height: 10rem;
  background: #1a4e74;
  margin: 3rem 0;
}
.login_sub_title {
  color: #1a4e74;
  font-weight: 300;
  font-size: 2.5rem;
}
.login_teaser_text {
  font-size: 2rem;
  color: #1a4e74;
  font-weight: 500;
  margin-bottom: 3rem;
  margin-top: -2rem;
}
.teaser_text_link {
  text-decoration: none;
  color: #1a4e74;
  font-weight: 700;
}
/* Responsive Styling */
@media (max-width: 768px) {
  .input_container {
    margin-bottom: 2rem;
  }
  .input {
    padding: 0.5rem 0;
    border-radius: 0.8rem;
    font-size: 1.8rem;
  }
  .input::placeholder {
    font-size: 1.8rem;
  }
  .radio_input {
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    font-size: 1.8rem;
    border-radius: 0.8rem;
  }
  .radio_label {
    font-size: 1.8rem;
  }
  .form_footer_text {
    font-size: 1.8rem;
  }
  .terms_checkbox .check_box {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 0.6rem;
    margin-left: 0;
  }
  .fancy_bar {
    font-size: 1.8rem;
  }

  .arrow {
    width: 10rem;
  }
  .textarea {
    height: 8rem;
  }

  .submit_btn {
    height: 6.5rem;
    margin: 6rem 0 3rem;
  }
  .login_sub_title {
    font-size: 2rem;
  }
  .login_button {
    padding: 1.6rem 0;
  }
}

.contact_bottom_text {
  margin-top: 2rem;
  font-size: 1.6rem;
  color: #1a4e74;
  font-weight: 600;
}
.address {
  color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .contact_bottom_text {
    font-size: 1.4rem;
  }
}

/* Horizontal line */
.hr_line {
  color: #1a4e74;
  margin: 3rem 0;
}
@media screen and (max-width: 768px) {
  .hr_line {
    margin: 2rem 0;
  }
}
/* Registration Subtitle */
.registration_sub_title {
  color: #1a4e74;
  font-weight: 300;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}
/* Forgot Password Modal */
.forgot_modal h2 {
  font-size: 4.5rem;
}
.forgot_modal .submit_btn {
  height: auto;
  padding: 1rem 3rem;
  border-radius: 1.5rem;
  margin: 1.8rem 0;
}
.modal_input {
  background: #dcecf4;
}
.modal_input::placeholder {
  color: #1a4e74;
}
