.share_container {
  border-radius: 2rem;
  max-width: 35rem;
  width: 100%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background: #fff;
}
.share_container input {
  height: 8rem;
  font-size: 1.6rem;
  direction: rtl;
}
.share_container button {
  padding: 0.3rem 2rem;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}
.share_container h3 {
  font-size: 2rem;
  background: #1a4e74;
  border-radius: 2rem 2rem 0 0;
  padding: 1rem 1.5rem;
  font-weight: 400;
  font-size: 2.4rem;
  color: #fff;
}
