
input::placeholder, input[type="date"],input[type="text"]  {
  text-align: center;
  font-family: 'Assistant', sans-serif ; 
  /*font-family: inherit;*/
 color: rgba(0, 0, 0, 0.38) ; 
  direction: rtl;
  font-size: 1.8rem;
  /*padding: 0.5rem 0;*/
  /*margin-bottom: 1px*/
} 

input[type="date"][disabled]::placeholder,
input[type="text"][disabled]::placeholder {
  text-align: center;
  
  /*font-size: 2.2rem;*/
  /*color: #1a4e74 ;*/
  color: rgba(0, 0, 0, 0.38);
  direction: rtl;
  padding: 0.5rem 0;
  /*margin-bottom:20px*/
  
}
/*input[type="date"][disabled]:not(:focus)::placeholder,*/
/*input[type="text"][disabled]:not(:focus)::placeholder {*/
/*  color: #1a4e74 !important;*/
/*}*/


/*input[type="date"]:disabled::placeholder,*/
/*input[type="text"]:disabled::placeholder {*/
/*  text-align: center;*/
/*  font-family: inherit;*/
/*  color: #1a4e74;*/
/*  direction: rtl;*/
/*  padding: 0.5rem 0;*/
/*}*/

/*input[type="date"]:disabled,*/
/*input[type="text"]:disabled {*/
  /* CSS styles for disabled input fields */
/*  text-align: center;*/
/*  font-family: inherit;*/
/*  color: #1a4e74;*/
/*  direction: rtl;*/
/*  padding: 0.5rem 0;*/
/*}*/



.profile_header {
  margin-bottom: 4.2rem;
}
.create_button_top {
  font-weight: 300;
}
.row_gap {
  margin: 3rem 0;
}
.img_active {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}
.profile_create .input {
  padding: 0.8rem 3rem;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}
.profile_create .upload_button {
  display: inline-block;
  border: 1px solid #fff;
  font-size: 2rem;
  font-weight: 300;
  padding: 0.8rem 3rem;
  color: #fff;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  margin-top: 2rem;
  border-radius: 1rem;
}
.profile_create .upload_button:hover {
  background: #fff;
  color: #1a4e74;
  border: 1px solid #1a4e74;
}
.date_title {
  font-size: 2rem;
  font-weight: 300;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
}

.life_axis .upload_text {
  border: none;
  padding: 0;
  border-radius: 0;
  margin: 0;
  color: #fff;
  font-weight: 300 !important;
}
.life_axis p {
  font-size: 2rem;
  color: #fff;
}
.life_axis input {
  padding: 1.5rem 2.5rem;
}
.life_axis img {
  width: 4.5rem;
  height: auto;
}
.life_axis .img_active_lifeAxis {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}
.button_container .add_axis {
  background-color: #70a4cb;
  border: 1px solid #fff;
  padding: 0.5rem 4rem;
}
.button_container .add_axis:hover {
  background-color: transparent;
  color: #fff;
}
.map .icon_container {
  padding: 2.5rem 0;
  border: 1px solid #fff;
  border-radius: 1rem;
  background-color: transparent;
  cursor: pointer;
}
.map .plus {
  color: #fff;
  font-size: 3.5rem;
  font-weight: 300;
}

.profile_create_button {
  margin-top: 8rem;
  gap: 2rem;
}
.profile_create_button button {
  padding: 1rem 6rem;
  font-size: 2.5rem;
  font-weight: 400;
}
.profile_create_button .submit {
  padding: 1rem 6rem;
}
.input_focus,
.input_focus:focus {
  color: #fff;
}
.privacy_button,
.privacy_button:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.privacy_button:focus {
  background-color: #fff;
  border-color: #1a4e74;
  color: #1a4e74;
}
.active_privacy {
  background-color: #fff;
  color: #1a4e74;
}
/* Media Query */
/* @media screen and (max-width: 768px) {
  .profile_create {
    padding: 0 2rem;
  }
  .profile_create .input {
    padding: 0.8rem 2rem;
  }
  .profile_create .upload_button {
    padding: 0.8rem 2rem;
  }
  .life_axis input {
    padding: 1.5rem 1.5rem;
  }
  .life_axis .icon_container {
    padding: 2.5rem 0;
  }
  .life_axis .plus {
    font-size: 3rem;
  }
  .profile_create_button {
    margin-top: 6rem;
  }
  .profile_create_button button {
    padding: 1rem 4rem;
  }
  .profile_create_button .submit {
    padding: 1rem 4.7rem;
  }
} */
.floating_button {
  border-radius: 1rem;
  position: absolute;
}
.teaser_text {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
}
@media screen and (max-width: 992px) {
  .profile_create_button .submit {
    padding: 1rem 4rem;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 576px) {
  .profile_create {
    padding: 0 1rem;
  }
  .profile_create .input {
    padding: 0.8rem 1rem;
  }
  .profile_create .upload_button {
    padding: 0.6rem 1rem;
    font-size: 1.6rem;
  }
  .life_axis input {
    padding: 1rem 1rem;
  }
  .life_axis .icon_container {
    padding: 2.5rem 0;
  }
  .life_axis .plus {
    font-size: 2.5rem;
  }
  .profile_create_button {
    margin-top: 4rem;
  }
  .profile_create_button button {
    padding: 1rem 2rem;
  }
  .profile_create_button .submit {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }
  .date_input {
    margin-top: 2.5rem;
  }
  .name_input {
    margin-bottom: 2.5rem;
  }
  .date_bottom_text {
    font-size: 1.4rem;
  }
  .check_box {
    height: 1.5rem;
    width: 1.5rem;
  }
  .button_container .add_axis {
    margin-top: 1rem;
    padding: 1rem 2rem;
  }
  .button_container p {
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    direction: rtl;
    margin-top: 1rem;
  }
  .life_axis p {
    border-bottom: 0.5px dashed #fff;
  }
  .life_axis img {
    width: auto;
    height: 5rem;
    /* border-radius: 50%; */
  }
}
