.widget_container {
  position: fixed;
  bottom: 7%;
  right: 0%;
  z-index: 12;
}
.widget_left {
  background: #1a4e74;
  color: #fff;
  font-size: 2.2rem;
  font-weight: 300;
  direction: rtl;
  border: 1px solid #1a4e74;
  padding: 1rem 6rem 1rem 1.5rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
}
.widget_right {
  transform: translateX(-75%);
  position: relative;
}
.widget_right img {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.cf_count {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(50%, -50%);
}
.cf_count span {
  font-size: 2rem;
  color: #1a4e74;
  font-weight: 600;
}
@media only screen and (max-width: 992px) {
  .widget_container {
    bottom: 20%;
    right: 2%;
  }
  .widget_left {
    font-size: 2rem;
    padding: 0.5rem 4rem 0.5rem 1.5rem;
  }
  .widget_right {
    transform: translateX(-50%);
  }
  .widget_right img {
    width: 6.5rem;
    height: 6.5rem;
  }
  .cf_count {
    height: 3rem;
    width: 3rem;
  }
  .cf_count span {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 768px) {
  .widget_container {
    right: initial;
    justify-content: center;
    bottom: 2%;
    width: 100%;
  }
}
