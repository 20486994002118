.verification_section {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  background-color: #dcecf4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verification_card {
  background-color: #fff;
  padding: 5rem;
  border-radius: 3rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-align: center;
}
.verification_img img {
  max-width: 25.6rem;
  width: 100%;
  height: auto;
}
.verification_text {
  margin-top: 3rem;
}
.verification_text h2 {
  font-size: 3.6rem;
}
.verification_text p {
  font-size: 1.8rem;
  margin: 1.5rem 0;
}
@media screen and (max-width: 768px) {
  .verification_card {
    padding: 3.5rem;
    border-radius: 2rem;
  }
  .verification_text {
    margin-top: 2rem;
  }
  .verification_text h2 {
    font-size: 2.8rem;
  }
  .verification_text p {
    font-size: 1.6rem;
    margin: 1.2rem 0;
  }
}
