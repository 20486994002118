.error_container h1 {
  font-size: 30rem;
  color: #1a4e74;
  margin: 0;
  padding: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 200;
}
.error_container h3 {
  font-size: 4rem;
  color: #1a4e74;
  margin: 0rem 0 3.5rem;
  font-weight: 300;
}
.error_container a {
  font-size: 2rem;
  color: #1a4e74;
  text-decoration: underline;
}
@media screen and (max-width: 1200px) {
  .error_container h1 {
    font-size: 24rem;
  }
  .error_container h3 {
    font-size: 2.8rem;
  }
  .error_container a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .error_container h1 {
    font-size: 16rem;
  }
  .error_container h3 {
    font-size: 2.4rem;
  }
  .error_container a {
    font-size: 1.6rem;
  }
}
