.modal_content {
  border-radius: 2rem;
  overflow: hidden;
}
.modal_title {
  font-size: 3.5rem;
  color: #1a4e74;
  font-weight: 300;
}
.dedicated_texts h4 {
  font-size: 2rem;
  font-weight: 600;
  color: #1a4e74;
}
.dedicated_texts input {
  color: #1a4e74;
  font-size: 1.5rem;
  font-weight: 400;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0.7rem 1.5rem;
  border-radius: 0.7rem;
  text-align: start;
  direction: rtl;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.09);
}
.dedicated_texts input::placeholder {
  color: #cacaca;
}

@media screen and (max-width: 768px) {
  .modal_title {
    font-size: 2.2rem;
  }
}

.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
.popup_image_text {
  top: 2rem;
  width: 100%;
}
.popup_image_text h3 {
  font-weight: 300;
  font-size: 2.6rem;
  color: #1a4e74;
}
.popup_image_text h6 {
  font-weight: 700;
  font-size: 1.8rem;
  color: #1a4e74;
}
.popup_image_text p {
  font-weight: 400;
  font-size: 1.2rem;
  color: #1a4e74;
}
.profile_candle_btn {
  bottom: 2rem;
  font-size: 1.4rem;
  z-index: 5;
  display: block;
  left: 50%;
  transform: translateX(-50%);
}
.profile_candle_btn:hover {
  background-color: #1a4e74;
  color: #fff;
  border-color: #1a4e74;
}
.counter_text {
  margin-top: 2rem;
  color: #1a4e74;
  font-size: 2.2rem;
}
.counter {
  background-color: #70a4cb;
  padding: 0.4rem 0;
}
.counter span {
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
}
.btn_close_sm {
  border: none;
  margin-left: 1rem;
  background-color: #70a4cb;
  padding: 0.4rem;
  border-radius: 0.8rem;
}
.btn_close_sm img {
  width: 3rem;
  height: 3rem;
}
