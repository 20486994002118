.demo_profile {
  background-color: #dcecf4;
}
.demo_profile .swiper img {
  height: auto;
  border-radius: 3rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
.image_container {
  padding: 0 12rem;
}
@media screen and (max-width: 768px) {
  .image_container {
    padding: 0 6rem;
  }
}
