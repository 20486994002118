.plans .plan_card {
  border: 2px solid #fff;
  border-radius: 3rem;
  padding: 4rem 8rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.plans .plan_card img {
  display: block;
  max-width: 25rem;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.plans .plan_card h3 {
  font-size: 5rem;
  color: #6097bf;
  margin-top: 5rem;
}
.plans .plan_card h4 {
  font-size: 4rem;
  color: #6097bf;
  margin-top: 2.2rem;
  margin-bottom: 3.2rem;
}
.plans .plan_card ul {
  list-style-type: '-';
  list-style-position: inside;
  padding: 0;
  margin: 0;
}
.plans .plan_card ul li {
  font-size: 2rem;
  color: #6097bf;
}
.plans .plan_card .first_card_texts {
  margin: 5rem 0;
}
.plans .plan_card h5 {
  font-size: 4rem;
  color: #6097bf;
}

.plans .plan_card button {
  width: 100%;
  margin-top: auto;
}

.plans .plan_card .second_list {
  margin-top: 2.5rem;
}
.plans .plan_card .second_list li {
  font-size: 2.5rem;
}

.plans .plan_card .first_plan_button {
  padding: 3rem 0.8rem;
}
.plans .plan_card .first_plan_button a {
  color: #fff;
}
.plans .plan_card .first_plan_button:hover a {
  color: #6097bf;
}

.plans .plan_card .second_plan_button {
  padding: 1.5rem 0.8rem;
}
/* Media Query */
@media screen and (max-width: 1200px) {
  .plans .plan_card {
    padding: 4rem 6rem;
  }
  .plans .plan_card h3 {
    font-size: 4rem;
  }
  .plans .plan_card h4 {
    font-size: 3rem;
  }
  .plans .plan_card h5 {
    font-size: 3rem;
  }
  .plans .plan_card .first_card_texts {
    margin: 3rem 0;
  }
}
@media screen and (max-width: 992px) {
  .plans .plan_card {
    padding: 3rem 5rem;
  }
  .plans .plan_card h3 {
    margin-top: 3rem;
  }
  .plans .plan_card h4 {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .plans .plan_card h3 {
    font-size: 3.5rem;
  }
  .plans .plan_card h4 {
    font-size: 2.6rem;
  }
  .plans .plan_card h5 {
    font-size: 2.6rem;
  }

  .plans .plan_card .second_list li {
    font-size: 2.2rem;
  }
  .plans .plan_card .second_list {
    margin-bottom: 3rem;
  }
}
