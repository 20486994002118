.accordion_header {
  position: relative;
}
.accordion_item {
  outline: none;
  border: none;
  background-color: transparent;
}

.accordion_body {
  background: #1a4e74;
  text-align: center;
  border-radius: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.accordion_button {
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 1rem !important;
}
.accordion_button::after {
  content: initial;
}

.accordion_title {
  font-size: 2.5rem;
  font-weight: 300;
  color: #1a4e74;
  text-align: center;
}
.button {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1a4e74;
  padding: 0.2rem 3rem;
  border: 0.5px solid #1a4e74;
  border-radius: 1rem;
}

.button {
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.button:hover {
  color: #fff;
}
@media screen and (max-width: 768px) {
  .accordion_title {
    font-size: 2rem;
  }
  .button {
    font-size: 1.2rem;
  }
}
