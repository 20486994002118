.head1{
  display: flex;
  justify-content: flex;
  color: #2c3e50;
  margin-right: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.container1{
  font-size: 18px;
  direction: rtl;
  font-family: Arial, sans-serif;
  height: 100%;
}

.button-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 5%;
}

.button-list button {
  padding: 10px;
  width: 180px;
  background-color: #3498db; 
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0;
}

.button-list button:hover {
  background-color: #2980b9; 
}

  .back-button {
      position: absolute;
      top: 10px; 
      left: 10px; 
      background-color: #7CB9E8;
  }

@media (max-width: 600px) {
  .container1 {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: center; 
    display: flex;
    flex-direction: column; 
    width: 60%;
  }

  aside {
    width: 60%;
  }

  .button-list {
    align-self: flex-start;
  }
}