.modal_content {
  background: #dbecf4;
}
.modal_header {
  border: none;
  padding: 2.5rem 2rem;
}
.modal_body {
  padding: 0 6rem 6rem;
}
.modal_body img {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.modal_header .modal_title {
  font-size: 3rem;
  font-weight: 400;
  margin-left: auto;
  color: #1a4e74;
  direction: rtl;
}
.modal_header button {
  width: 2.5em;
  height: 2.5em;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/2.5em auto no-repeat;
}
.modal_text {
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 2rem;
  color: #1a4e74;
}
.modal_text ol,
.modal_text ul {
  padding-right: 2rem;
}
@media only screen and (max-width: 600px) {
  .modal_header .modal_title {
    font-size: 2.5rem;
  }
  .modal_text {
    font-size: 1.6rem;
  }
  .modal_body {
    padding: 0 3rem 3rem;
  }
}
