.form {
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
}
.form_btn {
  background: #fff;
  border-radius: 1rem;
  color: #1a4e74;
  font-weight: 300;
  font-size: 2rem;
}
.form_btn:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.login_subtitle {
  color: #1a4e74;
  font-weight: 200;
  font-size: 2.5rem;
  margin-top: -2.5rem;
  margin-bottom: 7.5rem;
}
.left_panel {
  background: #1a4e74;
  padding: 6rem 5rem;
}
.left_panel h3,
.left_panel hr,
.left_panel h4,
.left_panel h5,
.left_panel p,
.left_panel a,
.left_panel span {
  color: #fff;
  font-weight: 300;
}
.left_panel h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.left_panel hr {
  margin: 0;
  font-size: 2px;
  color: #fff;
  font-weight: 600;
}
.left_panel h4 {
  font-size: 3.2rem;
}
.left_panel h5 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.left_panel .need_help a,
.left_panel .need_help span {
  font-size: 1.5rem;
  font-weight: 700;
  display: block;
}
.left_panel .need_help a:hover,
.left_panel .need_help span:hover {
  color: #fff;
}
.left_panel .need_help a:not(:last-child) {
  margin-bottom: 0.8rem;
}
.left_panel .need_help span {
  margin-bottom: 0.8rem;
  cursor: pointer;
}
/* Right Panel */
.right_panel {
  background-color: #fff;
  padding: 3.5rem 14rem;
}

.right_panel h2 {
  font-weight: 200;
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #1a4e74;
}
.right_panel hr {
  color: #1a4e74;
  font-size: 2px;
  margin: 2rem 0;
}
.right_panel h4 {
  font-weight: 300;
  font-size: 2rem;
  color: #1a4e74;
  margin-bottom: 2rem;
}
.right_panel .input,
.right_panel .input:focus {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 200;
  font-family: inherit;
  color: #1a4e74;
  direction: rtl;
  border-radius: 1rem;
  background-color: #dcecf4;
  border-color: #dcecf4;
  text-align: center;
}
.right_panel .input::placeholder {
  background-color: #dcecf4;
  color: #6097bf;
  font-weight: 200;
  text-align: center;
}
.right_panel .terms_checkbox {
  gap: 2.2rem;
}
.right_panel .form_footer_text {
  font-weight: 300;
  font-size: 1.5rem;
  color: #1a4e74;
  display: block;
}
.check_box {
  width: 1.239rem;
  height: 1.239rem;
  border: 1px solid #1a4e74;
  border-radius: 0.4rem !important;
}
.right_panel .submit_btn {
  border-radius: 1rem;
  padding: 1.5rem 0;
}

/* Input (type:radio) Element CSS */
.radio_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  background-color: #dcecf4;
  border-radius: 1rem;
  padding: 0.45rem 0;
}
.radio_input {
  background: #fff;
  width: 3rem;
  height: 2.8rem;
  color: #1a4e74;
  text-align: center;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 200;
  font-size: 1.6rem;
  position: relative;
  overflow: hidden;
}
.radio {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.radio_label {
  font-weight: 200;
  font-size: 1.8rem;
  color: #1a4e74;
}
.radio:checked + label {
  background-color: #6097bf;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_panel .forgot_password {
  display: block;
  font-weight: 400;
  font-size: 1.5rem;
  color: #1a4e74;
  text-decoration: none;
  margin-top: 1rem;
}
@media only screen and (max-width: 1400px) {
  .right_panel {
    padding: 3.5rem 10rem;
  }
}
@media only screen and (max-width: 1200px) {
  .right_panel {
    padding: 3.5rem 5rem;
  }
}
@media only screen and (max-width: 992px) {
  .right_panel h2 {
    background: #1a4e74;
    color: #fff;
    padding: 2rem 0;
    margin: -3.5rem -5rem 2rem;
  }
  .left_panel hr {
    margin: 2rem 0;
  }
}
@media screen and (max-width: 768px) {
  .login_subtitle {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 576px) {
  .login_subtitle {
    margin-bottom: 3rem;
  }
}
