.create_memory h2 {
  color: #1a4e74;
  font-size: 5rem;
  font-weight: 300;
  padding-bottom: 10rem;
}
.create_memory .img_container {
  width: 10rem;
  height: 10rem;
  margin: auto;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create_memory .img_container svg {
  color: #1a4e74;
  font-size: 4rem;
}
.create_memory h5,
.create_memory label {
  font-size: 2.5rem;
  font-weight: 300;
  color: #1a4e74;
  text-align: center;
  cursor: pointer;
}
.input_container {
  margin: 2.5rem 0 4rem;
}
.create_memory .textarea {
  height: 15rem;
  border-radius: 3rem;
  border: 1px solid #1a4e74;
  font-weight: 300;
}
.create_memory .input {
  border-radius: 2rem;
  border: 1px solid #6097bf;
  background-color: transparent;
  padding: 1rem;
  color: #6097bf;
  font-size: 2.5rem;
}
.create_memory .input::placeholder {
  color: #6097bf;
}
.create_memory .button {
  padding: 1.6rem 0;
  border: 1px solid #1a4e74;
  background-color: #1a4e74;
  font-weight: 300;
}
.create_memory .button:hover {
  background-color: #fff;
}
.button_container {
  margin-top: 3rem;
}
.img_container {
  cursor: pointer;
}
.line {
  width: 3rem;
  border-bottom: 1px solid #1a4e74;
  transition: all 0.3s ease;
}
.line:first-of-type {
  transform: rotate(90deg);
}

.img_container:hover .line {
  width: 5rem;
}
@media screen and (max-width: 768px) {
  .line {
    width: 2.5rem;
  }
  .img_container:hover .line {
    width: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .create_memory h2 {
    font-size: 3.6rem;
    padding-bottom: 6rem;
  }
  .create_memory .img_container {
    width: 6rem;
    height: 6rem;
  }
  .create_memory .img_container img {
    width: 3rem;
    height: 3rem;
  }
  .create_memory .img_container svg {
    font-size: 3rem;
  }
  .create_memory h5,
  .create_memory label {
    font-size: 2rem;
  }
  .create_memory .input {
    font-size: 2rem;
  }
  .input_container {
    margin: 1.6rem 0 2.2rem;
  }
  .create_memory .textarea {
    height: 12rem;
  }

  .create_memory .button {
    padding: 1.4rem 0rem;
  }
}
.preview_img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}
