.modal_header {
  background: #1a4e74;
}
.modal_title {
  font-size: 3.5rem;
  font-weight: 300;
  color: #fff;
  padding: 1rem 2rem;
  direction: rtl;
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
.bulletins {
  list-style: none;
  direction: rtl;
  /* padding-right: 2rem; */
}
.bulletins li {
  color: #1a4e74;
  font-size: 2rem;
}
.profile_qr {
  border: 2px solid #dcecf4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  padding: 2rem 1rem;
}
.profile_qr h3 {
  font-size: 2.2rem;
  font-weight: 300;
  color: #1a4e74;
  text-align: center;
  margin: 1rem 0;
}
.profile_qr h3 span {
  font-weight: 800;
}
.profile_qr p {
  font-size: 1.4rem;
  font-weight: 300;
  color: #1a4e74;
  text-align: center;
  direction: rtl;
}
.qr_button_sm {
  font-size: 2rem;
  font-weight: 300;
  color: #1a4e74;
  background: #dcecf4;
  padding: 0.5rem 0rem;
  border: 1px solid #dcecf4;
  border-radius: 1rem;
  width: 100%;
  transition: 0.3s all ease-in;
  margin-top: 1.5rem;
}
.qr_button_sm_03 {
  margin-top: 3.5rem;
}
.qr_button_sm:hover {
  background: #1a4e74;
  border: 1px solid #1a4e74;
  color: #fff;
}
.profile_qr img {
  border-radius: 2.5rem;
  /* width: auto; */
  height: 28.75rem;
}
.modal_footer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2.5rem 0rem 0rem;
  gap: 2rem;
}
.qr_button_lg {
  direction: rtl;
  font-size: 2rem;
  font-weight: 300;
  color: #fff;
  background: #1a4e74;
  padding: 1.5rem 0rem;
  border: 1px solid #dcecf4;
  border-radius: 2rem;
  width: 100%;
  transition: 0.3s all ease-in;
}
.qr_button_lg:hover {
  background: #dcecf4;
  border: 1px solid #1a4e74;
  color: #1a4e74;
}
/* QrPopUp Second Phase */
.profile_qr_selected {
  text-align: center;
  padding: 1rem 0rem;
}
.profile_qr_selected h3 {
  font-size: 2.5rem;
  font-weight: 300;
  color: #1a4e74;
  text-align: center;
  margin: 3rem 0;
}
.profile_qr_selected form {
  margin-bottom: 2.5rem;
}
.reaming_char {
  font-size: 1.6rem;
  font-weight: 300;
  color: #1a4e74;
  text-align: center;
  margin-top: -3.5rem;
  display: block;
}
.profile_qr_selected textarea {
  width: 100%;
  height: 13rem;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  border-radius: 3rem;
  resize: none;
  outline: none;
  padding: 2rem 3rem;
  font-size: 2rem;
  color: #1a4e74;
  direction: rtl;
}
.profile_qr_selected textarea::placeholder {
  color: #1a4e74;
  font-weight: 200;
  text-align: center;
}
.profile_qr_selected textarea:focus {
  outline: none;
}
.profile_qr_selected img {
  display: block;
  margin: auto;
  width: 100%;
  border-radius: 2.5rem;
  height: 20rem;
}

@media only screen and (max-width: 768px) {
  .modal_title {
    font-size: 2rem !important;
  }
  .profile_qr h3 {
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }
  .profile_qr p {
    font-size: 1.6rem;
  }
  .profile_qr .qr_button_sm {
    font-size: 1.6rem;
    margin: 1.5rem 0 1rem;
  }
  .profile_qr_selected h3 {
    font-size: 1.6rem;
    margin: 0rem 0 1rem;
  }
  .profile_qr img {
    height: 20rem;
  }
  .profile_qr_selected {
    font-size: 1.5rem;
  }
  .profile_qr_selected textarea::placeholder {
    font-size: 1.5rem;
  }
  .qr_button_lg {
    font-size: 1.8rem;
  }
  .profile_qr_selected {
    font-size: 1.5rem;
  }
}
.qr_button_sm_selected,
.qr_button_lg_selected {
  background-color: #1a4e74;
  color: #fff;
}
