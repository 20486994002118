.hero {
  background-image: url('https://res.cloudinary.com/lifecloud-qr/image/upload/v1676729184/Rectangle_2_1_wlfki3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 0;
  margin: 0;
  height: 90vh;
}

.hero_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.content {
  text-align: center;
  color: #ffffff;
}

.content h2 {
  font-weight: 200;
  font-size: 3.2rem;
  margin-top: 1.5rem;
}
.content h3 {
  font-weight: 200;
  font-size: 2.3rem;
  margin: 4.5rem 0 1.5rem;
  direction: rtl;
}

.content button,
.content a {
  font-size: 1.8rem;
  border-radius: 1.5rem;
  padding: 0.8rem 2rem;
  transition: all 0.3s ease-in;
}
.content button:hover,
.content a:hover {
  color: #1a4e74;
  background: #fff;
}
.content button span,
.content a span {
  font-weight: 600;
}
.banner_img {
  max-width: 45rem;
  width: 100%;
  filter: drop-shadow(4px 10px 10px rgba(0, 0, 0, 0.2));
}
.content .logo {
  width: 100%;
  max-width: 42rem;
}
@media screen and (max-width: 992px) {
  .banner_img {
    height: auto;
    width: 100%;
  }
  .content h2 {
    font-size: 3rem;
  }
  .content h3 {
    font-size: 1.8rem;
  }
  .content button,
  .content a {
    font-size: 1.6rem;
    max-width: 20rem;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .hero {
    background-image: url('https://res.cloudinary.com/lifecloud-qr/image/upload/v1676556700/121_1_rswe4d.png');
    height: 95vh;
  }

  .banner_img {
    max-width: initial;
    width: auto;
    height: 38rem;
    filter: drop-shadow(4px 10px 10px rgba(0, 0, 0, 0.2));
  }
  .content .logo {
    width: 100%;
    max-width: 25rem;
  }
  .content h2 {
    font-size: 2rem;
    margin-top: 0rem;
  }
  .content h3 {
    font-size: 1.6rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    font-weight: 300;
  }
  .content button,
  .content a {
    font-size: 1.4rem;
    padding: 0.6rem 1rem;
    border-radius: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .hero_content {
    align-items: flex-end;
    padding-bottom: 6rem;
  }
  .banner_img {
    height: 32rem;
  }
}
@media screen and (max-width: 380px) {
  .banner_img {
    height: 25rem;
  }
  .banner_content {
    margin-top: 0;
  }
  .content .logo {
    width: 100%;
    max-width: 22rem;
  }
  .hero_content {
    padding-bottom: 3rem;
  }
  .content h3 {
    margin: 3rem 0 1rem;
  }
}
