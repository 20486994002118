.story {
  position: relative;
  overflow: hidden;
  padding-bottom: 7.5rem;
}

.story_title {
  margin-bottom: 7.5rem;
  color: #6097bf;
  font-weight: 300;
}
.story_title span {
  display: block;
}
.story_title span:nth-child(2) {
  color: #1a4e74;
  font-weight: 500;
}
.story_card_img_container img {
  width: 100%;
  height: 100%;
}
.story_card_img_container {
  position: relative;
  max-width: 25rem;
  width: 100%;
  margin: 0 auto;
  border-radius: 3rem;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 2;
}

.story_card_description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a4e74;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 2rem;
  opacity: 0;
  transition: all 0.5s ease;
  font-size: 2rem;
}
.story_card_img_container:hover .story_card_description {
  opacity: 1;
}
.story .story_card_title {
  text-align: center;
  margin-top: 1rem;
  color: #1a4e74;
  font-size: 3rem;
  font-weight: 400;
}
.read_more_btn {
  font-size: 1.3rem;
  font-weight: 300;
  background-color: #dcecf4;
  border: none;
  color: #1a4e74;
  padding: 0.3rem 2rem;
  text-align: center;
  border-radius: 0.5rem;
  transition: 0.3s all ease-in;
  text-align: center;
  display: block;
  margin: 0 auto;
  white-space: pre-wrap !important;
}
.read_more_btn:hover {
  background-color: #1a4e74;
  color: #fff;
}
.lifestory_seemore_btn {
  background: #1a4e74;
  border: 1px solid #1a4e74;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 300;
  /* display: block; */
  text-align: center;
  margin: auto;
  padding: 0.5rem 1rem;
  transition: 0.3s all ease-in;
}
.lifestory_seemore_btn:hover {
  background: #fff;
  color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .story_card_img_container {
    max-width: 16rem;
  }
  .story_title {
    margin-bottom: 5rem;
    font-size: 2.2rem;
    width: 65%;
    margin: 0 auto 2rem;
  }
  .story .story_card_title {
    font-size: 1.8rem;
  }
  .story_card_description {
    font-size: 1.4rem;
  }
  .story_card_img_container {
    border-radius: 3rem;
  }
  .break {
    display: none;
  }
  .lifestory_seemore_btn {
    font-size: 1.6rem;
  }
}
