.title{
  display: flex;
  justify-content: flex;
  color: #2c3e50;
  margin-right: 10%;
}

.profile-transfer{
  font-size: 18px;
  direction: rtl;
  font-family: Arial, sans-serif;

}


.profile-transfer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title {
  align-self: flex-start;
  margin-bottom: 7%;
  color: #2c3e50;
  margin-right:5%;
  margin-top: 5%;
}



.comp1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  width: 80%;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.failure {
  background-color: #f8d7da;
  color: #721c24;
}
.profile-box {
  border: 1px solid #ccc; 
  padding: 10px; 
  flex: 1; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  border-radius: 4px; 
  width: 300px;
}
