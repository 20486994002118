.modal_body {
  background: #fff;
  padding: 3.5rem 2rem 1.5rem;
}
.modal_title {
  font-size: 4rem;
  font-weight: 300;
  color: #fff;
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
@media screen and (max-width: 768px) {
  .modal_title {
    font-size: 4rem;
  }
}
