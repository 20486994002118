.profile_navigator {
  margin: 8rem 0;
}
.profile_navigator button,
.profile_navigator a {
  font-size: 2rem;
  border-radius: 1rem;
  background-color: #1a4e74;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 3rem;
  border: 1px solid #1a4e74;
  transition: 0.3s all ease-in;
}
.profile_navigator a:hover {
  background: #fff;
  color: #1a4e74;
}
.profile_navigator button.active,
.profile_navigator a.active {
  border: 2px solid #dcecf4;
  background-color: #dcecf4;
  color: #6097bf;
}

@media screen and (max-width: 768px) {
  .profile_navigator {
    margin: 5rem 0;
  }
  .profile_navigator button,
  .profile_navigator a {
    font-size: 1.6rem;
    flex-grow: 1;
    padding: 0.5rem 0;
  }
}
