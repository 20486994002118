.bg {
  height: 35rem;
  background-color: #dcecf4;
}
.profile_upgrade_img {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
}
.profile_content {
  text-align: center;
  padding: 11.8rem 0;
}
.profile_content h2 {
  margin-top: 5rem !important;
}
.profile_upgrade {
  border-bottom: 2px solid #dcecf4;
}

.profile_upgrade img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.profile_upgrade img {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  border: 3px solid #ffffff;
}
.profile_upgrade h2 {
  color: #1a4e74;
  margin: 2rem 0 8rem;
  font-weight: 300;
  font-size: 6.5rem;
  color: #1a4e74;
}
.profile_upgrade input {
  cursor: pointer;
}
.profile_upgrade button {
  background-color: #1a4e74;
  padding: 0.5rem 3rem;
}
.loader {
  top: calc(50% - 6rem);
  left: calc(50% - 6rem);
  width: 12rem;
  height: 12rem;
  background-color: #1a4e74;
}

@media screen and (max-width: 768px) {
  .profile_content {
    padding: 8rem 0;
  }
  .profile_upgrade h2 {
    font-size: 4.5rem;
    margin: 5rem 0;
  }
  .profile_upgrade img {
    width: 25rem;
    height: 25rem;
  }
}
