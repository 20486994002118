.playIcon {
  position: absolute;
  bottom: 170%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 48px;
}
.memory_list button {
  padding: 0.8rem 3rem;
}
.memory_item .media {
  border-radius: 7rem;
  height: 25rem;
  width: 25rem;
  overflow: hidden;
  margin: auto;
}

.memory_content {
  margin-top: 2rem;
}
.memory_content h3 {
  font-size: 3rem;
  color: #6097bf;
  font-weight: 300;
}
.memory_content p {
  font-size: 2rem;
  color: #6097bf;
  font-weight: 300;
}
.memory_content h6 {
  font-size: 1.8rem;
  color: #6097bf;
  font-weight: 300;
}
.profile {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
}
.social_icons {
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}
.social_icons i {
  color: #6097bf;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.6rem;
  font-size: 1.4rem;
}

@media screen and (max-width: 1400px) {
  .memory_item .media {
    height: 25rem;
    width: 100%;
    border-radius: 7rem;
  }
}
@media screen and (max-width: 992px) {
  .memory_item .media {
    height: 18rem;
    width: 100%;
    border-radius: 6rem;
  }
}
@media screen and (max-width: 768px) {
  .memory_list {
    padding: 2rem 0;
  }
  .memory_list h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .memory_item .media {
    height: 20rem;
    width: 100%;
    border-radius: 5.5rem;
  }
  .memory_content h3 {
    font-size: 2.5rem;
  }
  .button_group {
    margin-top: 2rem;
    /* margin-bottom: 2.5rem; */
  }
  .button_group button {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 576px) {
  .memory_item .media {
    height: 20rem;
    border-radius: 6rem;
  }
}
@media screen and (max-width: 400px) {
  .memory_item .media {
    height: 18rem;
    border-radius: 4rem;
  }
}
@media screen and (max-width: 360px) {
  .memory_item .media {
    height: 16rem;
    border-radius: 3.5rem;
  }
}
