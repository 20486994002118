.profile_info {
  border-bottom: 2px solid #dcecf4;
}
.profile_info {
  margin-top: -13rem;
}

.profile_info_image img {
  height: 25rem;
  width: 25rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.profile_info_texts {
  margin-top: 1rem;
}
.profile_info_texts h2 {
  font-size: 5rem;
  color: #1a4e74;
  font-weight: 200;
}
.profile_info_texts h3,
.profile_info_texts h4,
.profile_info_texts .span {
  color: #1a4e74;
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 300;
}

/* Organization Info Styling */
.organization_container h5 {
  color: #1a4e74;
  font-size: 2rem;
  font-weight: 300;
  direction: rtl;
  margin-top: 0.5rem;
}
.organization_info {
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.organization_info p {
  color: #1a4e74;
  font-size: 2rem;
  font-weight: 300;
  direction: rtl;
}
.organization_info img {
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
  border: 1px solid #fff;
}
@media screen and (max-width: 768px) {
  .profile_info {
    margin-top: -6.5rem;
  }

  .profile_info_image img {
    height: 12rem;
    width: 12rem;
  }
  .profile_info_texts {
    margin-top: 1.5rem;
  }
  .profile_info_texts h2 {
    font-size: 3.2rem;
  }
  .profile_info_texts h3,
  .profile_info_texts h4 {
    font-size: 1.6rem;
  }

  .organization_info {
    padding: 0.3rem 0.8rem;
  }
  .organization_info img {
    width: 1.8rem;
    height: 1.8rem;
  }
  .organization_container h5,
  .organization_info p {
    font-size: 1.2rem;
  }
}
