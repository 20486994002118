.create_memory_modal_body {
  background: #dcecf4;
  padding-bottom: 5rem;
}
.modal_content {
  border-radius: 2rem;
  overflow: hidden;
}
.modal_title {
  font-size: 3rem;
  color: #1a4e74;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  .modal_title {
    font-size: 2.8rem;
  }
}

.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
