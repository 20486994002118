.review {
  position: relative;
  overflow: hidden;
  padding: 13rem 0;
}
.review img {
  z-index: 0;
}
.review_text {
  padding: 0 5rem;
}
.review_text h3 {
  font-size: 3.2rem;
  font-weight: 500;
  color: #6097bf;
}
.review_text h3,
.review_text h5 {
  direction: rtl;
  font-family: luizi;
}

.review_text h5 {
  font-size: 2rem;
  font-weight: 400;
  color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .review {
    padding: 7.5rem 0;
  }
  .review_text h3 {
    font-size: 2.4rem;
  }
  .review_text h5 {
    font-size: 2rem;
  }
}
