.gallery button {
  padding: 0.8rem 3rem;
}
.gallery_item {
  padding: 0 2rem;
}
.gallery_item .media {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: 8rem;
  overflow: hidden;
}
@media screen and (max-width: 1400px) {
  .gallery_item .media {
    border-radius: 6rem;
  }
}
@media screen and (max-width: 768px) {
  .gallery {
    padding: 2rem 0;
  }
  .gallery h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .gallery .button_group {
    margin-top: 2rem;
  }
  .gallery button {
    font-size: 1.6rem;
    /* margin-top: 3rem !important; */
  }
  .gallery_item {
    padding: 0 1rem;
  }
  .gallery_item .media {
    border-radius: 5rem;
  }
}
@media screen and (max-width: 576px) {
  .gallery_item .media {
    border-radius: 4rem;
  }
}
