.modal_header {
  border-bottom: none;
}
.modal_content {
  border-radius: 2rem;
  overflow: hidden;
  /* padding: 2rem 3rem; */
}
.modal_title {
  font-size: 4.5rem;
  font-weight: 200;
  color: #1a4e74;
}
.modal_sub_title {
  font-size: 1.6rem;
  font-weight: 300;
  color: #000;
  direction: rtl;
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
.modal_body {
  background: #f5fcff;
  padding: 2rem 3rem;
}
.modal_body h3 {
  font-weight: 600;
  font-size: 2rem;
  color: #1a4e74;
  direction: rtl;
}
.modal_body hr {
  color: #dcecf4;
  margin-top: 2rem;
}
.modal_body .title_hr {
  margin: auto;
  color: #1a4e74;
  margin-bottom: 1rem;
  width: 8rem;
}
.modal_body .cf_image {
  width: 10rem;
  height: 10rem;
  border: 0.5px solid #1a4e74;
  border-radius: 50%;
  cursor: pointer;
}
.modal_body .add_button {
  background: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  color: #1a4e74;
  padding: 0.1rem 2rem;
  border: 1px solid #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in;
  margin-top: 1rem;
}
.modal_body .add_button:hover {
  background: #1a4e74;
  color: #fff;
}

/* Dedicated Text */

.dedicated_texts input {
  color: #1a4e74;
  font-size: 1.5rem;
  font-weight: 400;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0.7rem 1.5rem;
  border-radius: 0.7rem;
  text-align: center;
  direction: rtl;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.09);
}
.dedicated_texts input::placeholder {
  color: #cacaca;
}
.counter {
  background-color: #fff;
  padding: 0.4rem 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.counter img {
  width: 3rem;
  height: 3rem;
  border: 0.5px solid #1a4e74;
  border-radius: 50%;
}
.counter span {
  font-size: 2rem;
  font-weight: 300;
  cursor: pointer;
  color: #1e272d;
}
.counter h5 {
  color: #1e272d;
  font-size: 2rem;
  font-weight: 300;
}
.increase_decrease button {
  border: 0;
  font-size: 1.6rem;
  color: #1a4e74;
  background: transparent;
  margin: 0 0.5rem;
  transition: 0.3s all ease;
}
.increase_decrease button:hover {
  transform: scale(1.5);
}

.btn_close_sm {
  border: none;
  margin-left: 1rem;
  background-color: #1a4e74;
  padding: 0.4rem;
  border-radius: 0.8rem;
  transition: 0.3s all ease-in;
}
.btn_close_sm:hover {
  transform: scale(1.05);
}
.btn_close_sm img {
  width: 3rem;
  height: 3rem;
}
.amount p {
  color: #1e272d;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 2rem;
  margin-bottom: 0;
}
.submit_btn {
  border-radius: 1rem;
  background: #1a4e74;
}
.amount_title {
  color: #1a4e74;
  font-size: 2rem;
  font-weight: 300;
  margin: 1rem 0;
  direction: rtl;
}
.submit_btn {
  width: 50%;
}
/* Media Query */
@media only screen and (max-width: 768px) {
  .modal_title {
    font-size: 3.5rem;
  }
  .modal_sub_title {
    font-size: 1.4rem;
  }
  .modal_body {
    padding: 2rem 2rem 1rem;
  }
  .modal_body .cf_image {
    width: 6rem;
    height: 6rem;
  }
  .modal_body .add_button {
    font-size: 1.3rem;
    padding: 0.1rem 1.5rem;
  }
  .increase_decrease button {
    margin: 0;
  }
  .counter h5,
  .counter span {
    font-size: 1.6rem;
  }
  .submit_btn {
    width: 100%;
  }
}
