.page_gap {
  padding: 8rem 0 6rem 0;
}

@media (max-width: 768px) {
  .page_gap {
    padding: 5rem 0;
  }
}
/* WhatsApp Widget */
.sticky_button {
  background: #45c755;
  border: 1px solid #45c755;
  border-radius: 0 50% 50% 0;
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
  position: fixed;
  bottom: 32%;
  left: 0%;
  padding: 0.6rem;
  z-index: 8;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
  transition: 0.3s all ease-in;
}
.sticky_button img {
  width: 3rem;
  height: 3rem;
}
.sticky_button:hover {
  background: #fff;
  color: #45c755;
}
@media (max-width: 768px) {
  .sticky_button {
    font-size: 1.4rem;
    bottom: 10%;
    /*left: 3%;*/
    padding: 1rem;
  }
  .sticky_button img {
    width: 2.5rem;
    height: 2.5rem;
  }
}
