.plus_minus {
  margin: 0 0.5rem 0.5rem 0;
}

.accordion_page_container .title {
  color: #6097bf;
  direction: rtl;
  display: flex;
  align-items: center;
  text-align: right;
  cursor: pointer;
  margin-bottom: 1.5rem;
}
.accordion_page_container .title h3,
.accordion_page_container .title .plus_minus {
  font-size: 4rem;
}

.content {
  opacity: 0;
  color: #6097bf;
  direction: rtl;
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  font-size: 2.4rem;
  text-align: right;
}
.content.show {
  opacity: 1;
  height: auto;
  overflow: auto;
}

.qa_content {
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
}
.policies_content a {
  color: #6097bf;
}
@media only screen and (max-width: 768px) {
  .content,
  .qa_content {
    font-size: 2rem;
  }
  .accordion_page_container .title h3,
  .accordion_page_container .title .plus_minus {
    font-size: 3rem;
  }
}
