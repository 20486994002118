.users_profile h3 {
  font-size: 2.8rem;
  color: #6097bf;
  text-align: center;
  margin: 1.8rem 0;
}
.users_profile_item img {
  transition: all 0.3s ease-in-out;
}
.users_profile_item img:hover {
  transform: scale3d(1.05, 1.05, 1.05);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.users_profile_item button {
  padding: 0rem 2.4rem;
  background-color: #1a4e74;
}
.icon_container {
  background-color: #abc9db;
  cursor: pointer;
  transition: all 0.3s ease;
}
.icon_container svg {
  fill: #fff;
  font-size: 15rem;
}

.line {
  width: 10rem;
  border-bottom: 1px solid #fff;
  transition: all 0.3s ease;
}
.line:first-of-type {
  transform: rotate(90deg);
}
.icon_container:hover {
  background-color: #1a4e74;
}
.icon_container:hover .line {
  background-color: #1a4e74;
  width: 15rem;
}
@media screen and (max-width: 768px) {
  .icon_container svg {
    font-size: 10rem;
  }
  .line {
    width: 8rem;
  }
  .icon_container:hover .line {
    width: 12rem;
  }
}
