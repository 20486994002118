.button {
  border-radius: 3rem;
  border: none;
  font-size: 2.4rem;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.8rem 3rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid transparent;
  z-index: 3;
}
.button_spinner {
  font-size: initial;
}
.button_contained,
.button_contained:focus {
  background-color: #1a4e74;
  color: #fff;
}
.button_contained a {
  color: #fff;
}
.button_contained:hover {
  border: 1px solid #1a4e74;
  background-color: transparent;
  color: #1a4e74;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.button_contained:hover a {
  color: #1a4e74;
}

.button_outline,
.button_outline:focus {
  color: #1a4e74;
  background: transparent;
  border: 1px solid #1a4e74;
}

.button_outline a {
  color: #1a4e74;
}
.button_outline:hover {
  border: 1px solid transparent;
  background-color: #1a4e74;
  color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.button_outline:hover a {
  color: #fff;
}
.button:disabled,
.button[disabled] {
  border: 1px solid #1a4e74;
  background-color: #1a4e74;
  color: #fff;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .button {
    font-size: 1.8rem;
    padding: 0.6rem;
    border-radius: 1.5rem;
  }
}
