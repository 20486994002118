.section {
  position: relative;
}
.cloudImg {
  position: absolute;
  width: 20%;
}
.cloudImgUp {
  top: -2px;
}
.cloudImgDown {
  bottom: -2px;
}
.cloudImg.left {
  left: 0;
}
.cloudImg.right {
  right: 0;
}

.section_gap {
  margin: 7.5rem 0;
}
.section_padding {
  padding: 7.5rem 0;
}
@media screen and (max-width: 768px) {
  .section_gap {
    margin: 3rem 0 4rem;
  }
  .section_padding {
    padding: 3rem 0 4rem;
  }
  .cloudImg {
    width: 30%;
  }
}
/* @media screen and (max-width: 576px) {
  .cloudImg {
    width: 30%;
  }
} */
