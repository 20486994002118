.main {
  padding-bottom: 5rem;
}
/* Header */
.qr_hero {
  background-image: url('https://res.cloudinary.com/lifecloud-qr/image/upload/v1674544320/Rectangle_2_6_isa5sf.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 81.8rem;
  position: relative;
}
.hero_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 12rem 0;
}
.hero_menu .logo {
  width: 18rem;
  cursor: pointer;
}
.hero_menu .menu_burger {
  width: 6rem;
  cursor: pointer;
  height: 6rem;
}
.hero_content {
  text-align: center;
  position: absolute;
  width: 68%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero_content h3 {
  font-size: 6rem;
  font-weight: 300;
  color: #fff;
  direction: rtl;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}

.hero_content .header_btn {
  font-weight: 200;
  font-size: 2rem;
  color: #fff;
  background: #1a4e74;
  padding: 1rem 5rem;
  border: 1px solid #1a4e74;
  border-radius: 1rem;
  transition: 0.3s all ease-in;
  display: inline-block;
  text-align: center;
  margin: 4rem 0 3rem;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.15);
}
.hero_content .header_btn_02 {
  background: #fff;
  color: #1a4e74;
}

.hero_content h4 {
  font-size: 2rem;
  font-weight: 200;
  color: #fff;
  text-align: center;
  text-decoration: underline;
  direction: rtl;
  cursor: pointer;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.hero_content .header_btn:hover {
  background: #fff;
  color: #1a4e74;
}
.hero_content .header_btn_02:hover {
  background: #1a4e74;
  color: #fff;
}
.navigation {
  padding: 0;
  position: absolute;
  right: 0%;
  width: max-content;
  z-index: 2;
}
.navigation li {
  background: #ffffff;
  border: 0.5px solid #1a4e74;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding: 0.6rem 3rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
}
.navigation li a {
  display: block;
  font-weight: 200;
  font-size: 2.2rem;
  color: #1a4e74;
  transition: all 0.3s ease;
  direction: rtl;
  text-align: right;
}
.navigation li:hover {
  background: #1a4e74;
}
.navigation li:hover a {
  color: #fff;
}
/* Main Body */
.qr_container {
  margin-top: -18rem;
  z-index: 2;
  position: relative;
  /* transform: translateY(-18rem); */

  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  padding: 8rem 10rem;
}
.qr_container h3 {
  font-size: 5rem;
  font-weight: 200;
  color: #1a4e74;
  direction: rtl;
}

.qr_container hr {
  color: #1a4e74;
  width: 96%;
  margin: 2rem 0 3.5rem;
  height: 0.5px;
  margin-left: auto;
}
.qr_container .section_hr {
  margin: 10rem auto;
}
.qr_container p {
  font-size: 2.5rem;
  font-weight: 200;
  color: #1a4e74;
  direction: rtl;
}
.qr_container a {
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  background: #1a4e74;
  padding: 0.8rem 2rem;
  border: 1px solid #1a4e74;
  border-radius: 1rem;
  transition: 0.3s all ease-in;
  margin-top: 5rem;
}
.qr_container a:hover {
  background: #fff;
  color: #1a4e74;
}
.text_logo {
  width: 12rem;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .hero_menu .logo {
    width: 15rem;
  }
  /* Hero */
  .mobile_image {
    margin-top: -12rem;
  }
  .hero_content h3 {
    font-size: 2.7rem;
    margin-top: 2.5rem;
  }
  .hero_content h4 {
    font-size: 1.8rem;
  }
  .hero_menu {
    padding: 3rem 8% 0;
  }
  .hero_content .header_btn {
    margin: 3rem 0 1rem;
    font-size: 1.8rem;
    padding: 0.8rem 3rem;
  }
  .hero_content .header_btn_02 {
    margin: 3rem 0;
  }
  .qr_container p {
    font-size: 2rem;
  }

  .hero_content {
    width: 80%;
    top: 36%;
  }
  .hero_menu .menu_burger {
    width: 4rem;
    height: 4rem;
  }
  .navigation {
    right: -25%;
  }

  /* QR */
  .qr_container {
    margin-top: -18rem;
    padding: 0rem 3rem 3.5rem;
  }
  .qr_container h3 {
    font-size: 4.2rem;
  }
  .qr_container .section_hr {
    margin: 4rem auto;
  }
  .qr_container a {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 576px) {
  .main {
    padding: 0 5% 5rem;
  }
}
