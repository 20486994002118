.social_login {
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.social_btn {
  font-weight: 300;
  font-size: 2rem;
  border-radius: 1rem;
}
.social_btn img {
  width: 3rem;
}
/* candle modal stuff */
.likeBtn {
   box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.05);
   border-radius: 10px;
   height:4rem;
   padding:1rem;
   box-sizing:border-box;
   width:auto;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   direction: rtl;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    color: #BCBCBC;
}

.likeBtn span {
  color:#000;
  width: auto;
}

.socialIcons {
  width:auto;
  cursor:pointer;
}

.socialIcons img {
  height:25px;
  
}

/* .socialLogin {
  background: #fff;
  border-radius: 1rem;
  color: #1A4E74;
  font-weight: 300;
  font-size: 2rem;
}
.socialLogin:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.socialLogin:hover {
  color: #1A4E74;
} */
@media only screen and (max-width: 992px) {
  .social_login {
    flex-direction: column-reverse;
  }
}
