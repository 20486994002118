.action a {
  display: block;
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  padding: 1.6rem 0;
}
@media screen and (max-width: 768px) {
  .action a {
    font-size: 2rem;
  }
}
