.remember {
  position: relative;
  overflow: hidden;
  background: #f5fcff !important;
  padding: 4.5rem 0;
  margin-top: 0;
}
.video_wrapper {
  position: relative;
}
.video,
.video_cover_img {
  border-radius: 2rem;
  position: absolute;
  transform: translateY(-20%);
  cursor: pointer;
}
.video_cover_img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
}
.video {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 40rem;
}

.video_cover_img {
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}
.hide_cover {
  z-index: -2;
  opacity: 0;
}
.show_cover {
  z-index: 2;
  opacity: 1;
}
.remember_title {
  margin-bottom: 4rem;
}
.remember_title h2 {
  font-size: 4rem;
  color: #1a4e74;
  font-weight: 400;
}
.remember_title h3 {
  font-size: 3rem;
  color: #6097bf;
  font-weight: 300;
  /* margin-bottom: 7rem; */
}

.remember_up {
  /* padding-bottom: 2.5rem; */
  /* margin-top: 3rem; */
}
.remember_up hr {
  color: #1a4e74;
  width: 40%;
  margin-left: auto;
  /* text-align: right; */
}
.remember_down {
  background-color: #f5fcff;
  padding: 0rem 0 9rem;
}

.remember .remember_up .remember_text {
  color: #1a4e74;
  font-weight: 200;
  font-size: 3.5rem;
  text-align: right;
  line-height: 129.68%;
}
.remember .remember_down {
  background-color: #f5fcff;
}
.remember .remember_down .remember_text {
  font-size: 4rem;
  color: #1a4e74;
  text-align: right;
  font-weight: 300;
  line-height: 120.18%;
  z-index: 5;
  margin-top: 0;
}

@media screen and (max-width: 1200px) {
  .video {
    height: auto;
  }
  .video_cover_img {
    height: 30.3047rem;
  }
  .remember .remember_title h2,
  .remember .remember_title h3 {
    font-size: 3rem;
  }
  .remember .remember_up .remember_text {
    font-size: 2.8rem;
  }
  .remember .remember_down .remember_text {
    font-size: 3rem;
  }
}
@media screen and (max-width: 992px) {
  .video_cover_img {
    height: 100%;
  }
  .video_wrapper {
    margin-bottom: 3rem;
  }
  .video {
    position: static;
  }
  .video,
  .video_cover_img {
    transform: translateY(0);
  }

  .remember .remember_text {
    font-size: 2.4rem;
  }
  .remember_down {
    padding: 3rem 0 8rem;
  }
}
@media screen and (max-width: 992px) {
  .remember .remember_up .remember_text {
    text-align: center;
  }
  .remember_down {
    padding: 5rem 0;
  }
  .remember .remember_down .remember_text {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .remember {
    padding: 3.5rem 0;
    margin-top: 0;
  }
  .remember_down {
    padding: 0;
  }
  .remember .remember_title h2 {
    font-size: 2.5rem;
    padding: 0 3rem;
    font-weight: 300;
  }
  .remember .remember_title h3 {
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0 1rem;
    margin-bottom: 3rem;
    letter-spacing: 0.6px;
  }
  .remember .remember_up .remember_text {
    font-size: 2rem;
    text-align: center;
    padding: 0 4rem;
  }
  .remember .remember_down .remember_text {
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    padding: 2rem 4rem 2rem;
  }
  .remember_title {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 576px) {
  .video_container {
    padding: 0;
  }
  .video_container .video,
  .video_cover_img {
    border-radius: 0;
  }
}
