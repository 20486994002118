.profileCover .wallImg {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.profileCover {
  position: relative;
}
.relocate_cover {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 15;
}
.relocate_cover button {
  border: 0;
  background: transparent;
  font-size: 3rem;
  color: #1a4e74;
  font-weight: 600;
}
.relocate_cover .reLocator_submit {
  border: 1px solid #1a4e74;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  transition: 0.3s all ease-in;
}
.relocate_cover .reLocator_submit:hover {
  background: #1a4e74;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .profileCover .wallImg {
    aspect-ratio: 16 / 9;
    height: auto;
  }
  .relocate_cover {
    gap: 0;
  }
  .relocate_cover button {
    font-size: 2rem;
  }
}
