.center_content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  padding: 5rem 0;
}

.cloudImg {
  position: absolute;
}
.cloudImgUp {
  top: -2px;
}

.cloudImgDown {
  bottom: -2px;
}

.cloudImg.left {
  left: 0;
}
.cloudImg.right {
  right: 0;
}
@media screen and (max-width: 992px) {
  .cloudImg {
    width: 40%;
  }
}
@media screen and (max-width: 768px) {
  .center_content {
    padding: 7.5rem 0;
  }
}

@media screen and (max-width: 576px) {
  .cloudImg {
    width: 50%;
  }
}
