.candle_flower_buttons {
  position: fixed;
  top: 0;
  left: 8%;
  z-index: 5;
  justify-content: center;
  border-radius: 2rem;
}
.candle,
.flower {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
}
.candle_img,
.flower_img {
  cursor: pointer;
  position: relative;
}
.candle_img img,
.flower_img img {
  border: 2px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 11rem;
  height: 11rem;
}
.candle_img.zero_candle::after {
  content: 'הדלקת נר';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 119, 155, 0.7);
  z-index: 5;
  display: block;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: 500;
}
.flower_img.zero_flower::after {
  content: 'הנחת פרח';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 119, 155, 0.7);
  z-index: 5;
  display: block;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: 500;
}
.candle_users,
.flower_users {
  position: relative;
}
.candle_users ul,
.flower_users ul {
  background: #1a4e74;
  border: 2px solid #dcecf4;
  border-radius: 2rem 0 0 2rem;
  padding: 1.6rem 2rem;
  width: 20rem;
  max-height: 11rem;
  color: #ffffff;
  overflow: auto;
}
.candle_users ul li,
.flower_users ul li {
  font-size: 1.4rem;
}
.candle_count,
.flower_count {
  background: #dcecf4;
  border-radius: 50%;
  color: #1a4e74;
  font-size: 1.8rem;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  z-index: 5;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .candle,
  .flower {
    gap: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .candle_flower_buttons {
    gap: 1rem !important;
  }
  .candle_img img,
  .flower_img img {
    width: 7.5rem;
    height: 7.5rem;
  }
  .candle_img::after,
  .flower_img::after {
    font-size: 1.4rem;
  }
  .candle_users ul,
  .flower_users ul {
    padding: 1.2rem 1.6rem;
    width: 16.8rem;
    max-height: 7.5rem;
  }
  .candle,
  .flower {
    gap: 1.2rem;
  }
  .candle_count,
  .flower_count {
    font-size: 1.6rem;
    width: 3.2rem;
    height: 3.2rem;
  }
}
/* ScrollBar Styling */
.candle_users ul::-webkit-scrollbar,
.flower_users ul::-webkit-scrollbar {
  width: 1rem;
}
.candle_users ul::-webkit-scrollbar-track,
.flower_users ul::-webkit-scrollbar-track {
  background: #dcecf4;
  /* border-radius: 2rem; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset;
}
.candle_users ul::-webkit-scrollbar-thumb,
.flower_users ul::-webkit-scrollbar-thumb {
  background: #6097bf;
  border-radius: 0.2rem;
  background-clip: content-box;
  transition: all 0.3 ease-in;
}
