.notification h2 {
  font-size: 7.2rem;
  font-weight: 300;
  color: #1a4e74;
}
.notification h3 {
  text-align: center;
  font-size: 4rem;
  color: #46779b;
  font-weight: 200;
  border-bottom: 0.3px solid #abc9db;
}
.pillbox {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.notification_container {
  border-radius: 2rem;
  padding: 1.5rem 3rem 1.5rem 2rem;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.notification_container img {
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
}
.notification_container h4 {
  color: #46779b;
  font-weight: 300;
  font-size: 2.2rem;
  text-align: right;
  direction: rtl;
}
.notification_container p,
.notification_container span {
  color: #46779b;
  font-weight: 300;
  font-size: 2.2rem;
}

.friend_request button {
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
  background: #1a4e74;
  border: 1px solid #1a4e74;
  border-radius: 1rem;
  padding: 0.5rem 4rem;
  transition: 0.3s all ease-in;
}
.friend_request button:hover {
  color: #1a4e74;

  background: #fff;
}
.friend_request .active_btn {
  background: #fff;
  color: #1a4e74;
}
.friend_request .active_btn:hover {
  background: #1a4e74;
  color: #fff;
}
.loading_text {
  color: #46779b;
  font-weight: 500;
  font-size: 2.2rem;
  text-align: center;
  margin-top: 1.2rem;
}
.accordion_header {
  background: transparent !important;
}
.accordion_header button {
  background-color: transparent !important;
  border: 0;
}
.accordion_header button:focus {
  box-shadow: none !important;
}
.accordion_header:active {
  box-shadow: none !important;
}
.see_more {
  font-weight: 400;
  font-size: 1.5rem;
  text-decoration-line: underline;
  display: block;
  text-align: center;
  color: #1a4e74;
  margin: 2.5rem 0 0.8rem;
}
@media screen and (max-width: 768px) {
  .notification h2 {
    font-size: 5.6rem;
  }
  .notification h3 {
    font-size: 3rem;
  }
  .notification_container h4 {
    font-size: 1.5rem;
  }
  .pillbox {
    gap: 1.5rem;
  }
  .notification_container {
    padding: 1rem 2rem 1rem 1rem;
  }
  .notification_container p,
  .notification_container span {
    font-size: 1.4rem;
  }
  .date_time {
    flex-direction: column-reverse;
  }
  .friend_request button {
    font-size: 1.4rem;
    padding: 0.3rem 1.5rem;
  }
  .notification_container img {
    height: 4rem;
    width: 4rem;
  }
  .friend_request button {
    font-size: 1.4rem;
    padding: 0.3rem 1.5rem;
  }
}
