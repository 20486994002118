.modal_title {
  font-size: 4rem;
  font-weight: 200;
  color: #fff;
  direction: rtl;
}
.modal_body {
  padding: 2.5rem 2.5rem 4.5rem;
  text-align: center;
}

.modal_body .input_container .input_item {
  margin-top: 2.5rem;
}
.modal_body input {
  font-weight: 200;
  text-align: center;
  font-size: 2rem;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1.5rem 0;
  direction: rtl;
}
.modal_body input::placeholder {
  color: #1a4e74;
}
.address_form_text p {
  font-size: 2rem;
  font-weight: 300;
  color: #000;
  margin: 2.5rem 0 3rem;
  direction: rtl;
}
.button_container button {
  padding: 1rem 3rem;
  background: #1a4e74;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 200;
  border: 1px solid #1a4e74;
  margin-top: 1.5rem;
  transition: 0.3s all ease-in;
}
.button_container button:hover {
  background: #fff;
  color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .modal_title {
    font-size: 2.5rem;
  }
  .modal_body {
    padding: 1rem 2.5rem 2.5rem;
  }
  .modal_body .input_container {
    margin-top: 0rem;
  }
  .address_form_text p {
    font-size: 1.6rem;
  }
  .button_container button {
    width: 100%;
  }
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
