.title {
  font-weight: 300;
  font-size: 4rem;
  color: #1a4e74;
  margin-left: auto;
}
.notification_modal h3 {
  font-weight: 300;
  font-size: 2.6rem;
  text-align: center;
  color: #1a4e74;
}
.notification_modal h3:last-of-type {
  margin-top: 3.5rem;
}
