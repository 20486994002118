.social_footer h2 {
  font-size: 3.5rem;
  color: #1a4e74;
  font-weight: 300;
}

.social_links {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  gap: 2.6rem;
  margin-top: 3.2rem;
}
.social_links li {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.social_links li img {
  transition: all 0.3s ease;
}
.social_links li img:hover {
  transform: scale(1.1);
}

.social_footer {
  position: relative;
  overflow: hidden;
}
.cloudImg {
  position: absolute;
  bottom: -2px;
}
.cloudImg.left {
  left: 0;
}
.cloudImg.right {
  right: 0;
}
@media screen and (max-width: 992px) {
  .cloudImg {
    width: 60%;
  }
}

@media screen and (max-width: 576px) {
  .cloudImg {
    width: 50%;
  }
  .social_links li img {
    height: 4.5rem;
  }
}
