.bio {
  text-align: center;
}
.bio_up {
  background-color: #dcecf4;
  padding: 5rem 0rem 5rem;
}
.bio_up img {
  max-width: 32rem;
  width: 100%;
  height: auto;
}
.bio p {
  font-weight: 300;
}

.bio_up p {
  color: #1a4e74;
  font-size: 3rem;
}
.bio_down p {
  color: #6097bf;
  font-size: 3rem;
}
.bio_up p:last-of-type {
  /* margin-top: 3rem; */
}
.bio_down {
  background-color: #f5fcff;
  padding: 6rem 0 2.5rem;
}
.bio_down img {
  width: 13rem;
  height: auto;
  cursor: pointer;
  margin-top: 3rem;
}
.signature_text {
  color: #1a4e74;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}
/* .bio_down p:last-of-type {
  margin-top: 2rem;
}
.bio_down h4 {
  font-size: 2.8rem;
  color: #6097bf;
  margin-top: 2rem;
}
.bio_down h2 {
  font-size: 4.5rem;
  color: #6097bf;
} */
@media screen and (max-width: 768px) {
  .bio_up {
    padding: 3.5rem 0rem 3rem;
  }
  .bio_up img {
    max-width: 20rem;
  }
  .bio_up p {
    font-size: 2rem;
    padding: 0 2rem;
  }
  .bio_down {
    padding: 4rem 0 3rem;
  }
  .bio_down img {
    width: 15rem;
    margin-top: 3rem;
  }
  .bio_down p {
    font-size: 2.2rem;
    padding: 0 2.2rem;
  }
  .bio_down h4 {
    font-size: 2.4rem;
  }
  .bio_down h2 {
    font-size: 3.5rem;
  }
}
