.title{
  display: flex;
  justify-content: flex;
  color: #2c3e50;
  margin-right: 10%;
}

.profile-transfer{
  font-size: 18px;
  direction: rtl;
  font-family: Arial, sans-serif;

}
.profile-transfer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title {
  align-self: flex-start;
  margin-bottom: 7%;
  color: #2c3e50;
  margin-right:5%;
  margin-top: 5%;
}

.input-container1 {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
}
.input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  margin-bottom: 5px;
  align-self: flex-end;
}

.input {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  color:#000000;
  text-align: left;
}

.comp1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  width: 80%;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.failure {
  background-color: #f8d7da;
  color: #721c24;
}

.dropdown1 {
  position: relative;
  width: 45%; 
  margin-right: 50px;
}

.dropdown1 select {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  color: #000000;
  text-align: left;
}