.axis_container {
  /* background-image: url('../../assets/profile-life-axis-img/line.svg'); */
  /* background-repeat: no-repeat;
  background-position: right; */
  margin-top: 3rem;
  position: relative;
  z-index: 3;
}
.life_axis .life_axis_item {
  margin-bottom: 5rem;
  margin-right: 2rem;
}
.life_axis_item h3,
.life_axis_item p {
  text-align: right;
  color: #6097bf;
}
.life_axis_item h3 {
  font-size: 3rem;
}
.life_axis_item p,
.life_axis_item p a {
  /* font-size: 2.2rem; */
  color: #1a4e74;
  font-size: 2.2rem;
  font-weight: 300;
}

.life_axis_item img {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 768px) {
  .life_axis_item {
    flex-direction: column-reverse;
  }
  .life_axis .life_axis_item {
    margin-bottom: 3rem;
  }
  .life_axis_item h3,
  .life_axis_item p {
    text-align: center;
  }
  .life_axis {
    padding: 2rem 0;
  }
  .life_axis h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .life_axis_item h3 {
    font-size: 2.4rem;
  }
  .life_axis_item p {
    font-size: 1.6rem;
  }
  .axis_container {
    background-position: 80%;
  }
  .axis_container h3 {
    font-size: 1.8rem;
  }
  .axis_container button {
    font-size: 1.6rem;
  }
  .life_axis .life_axis_item {
    margin-right: 0;
  }
}
