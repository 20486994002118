.organization_slider {
  background-color: #f1f1f1;
  padding: 1rem 0;
}
.img_container {
  background: #ffffff;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25rem;
  height: 7rem;
  padding: 0 3rem;
}

.img_container img {
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .img_container {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 768px) {
  .img_container {
    border-radius: 2rem;
    max-width: 16rem;
    padding: 0 1rem;
  }
}
