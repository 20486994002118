.notification_count {
  background: #fff;
  color: #1a4e74;
  border: 1px solid #1a4e74;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.2rem;
  bottom: 40%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
