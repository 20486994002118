.banner {
  width: 100%;
  height: 40rem;
}
.blog_container h2 {
  margin-top: 6rem;
}
.search_container {
  padding: 2rem 5rem;
  background: #fff;
  border-radius: 3rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.blog_post_search_container {
  margin: 4rem 0 6rem;
}
.search_container input {
  font-size: 2rem;
  border: none;
  border-bottom: 0.5px solid #46779b;
  border-radius: 0;
  text-align: center;
  direction: rtl;
  color: #1a4e74 !important;
}
.search_container input::placeholder {
  color: #46779b;
}
.search_container input:focus {
  box-shadow: none;
}
.menu {
  margin-bottom: 3rem;
}
.menu ul li {
  color: #46779b;
  font-size: 2rem;
  font-weight: 200;
}
.menu ul li span {
  color: #abc9db;
}
.menu ul li button {
  border: none;
  font-size: 2rem;
  font-weight: 300;
  color: #1a4e74;
  transition: 0.3s all ease-in;
}
.menu ul li button:hover {
  background: #1a4e74;
  color: #fff;
}
.menu ul li button.active {
  background: #1a4e74;
  color: #fff;
}
.menu hr {
  width: 50%;
  margin: auto;
  color: #abc9db;
}
.card {
  border-radius: 1rem;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.card img {
  /* height: 20rem; */
  aspect-ratio: 16 / 9;
}
.blog_post_card img {
  height: 40rem;
  /* aspect-ratio: 16 / 9; */
}
.card_body {
  padding: 1.5rem 3.5rem;
}
.card h5 {
  font-size: 2.2rem;
  font-weight: 200;
  color: #1e272d;
}
.card hr {
  width: 100%;
}
.card_body p {
  font-size: 1.5rem;
  font-weight: 200;
  color: #1e272d;
  direction: rtl;
  margin-top: 1rem;
}
.card_body button {
  background: #1e272d;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 200;
  padding: 1rem 5rem;
  margin-top: 2rem;
  transition: 0.3s all ease;
}
.card_body button:hover {
  background: #fff;
  color: #000;
}
.contact_container {
  background: #dcecf4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding: 3.5rem;
  margin: 10rem 0;
}
.contact_container p {
  font-size: 2rem;
  font-weight: 300;
  color: #1e272d;
  direction: rtl;
  text-align: center;
}
.contact_container input {
  border: none;
  padding: 0.3rem 2rem;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 100%;
  font-weight: 200;
}
.contact_container input::placeholder {
  color: #46779b;
}
.contact_container button {
  padding: 0.3rem 0;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  font-weight: 200;
  width: 100%;
}
.contact_item {
  flex: 1 1 0;
}
.similar_blog_post .similar_title {
  font-size: 3rem;
  font-weight: 200;
  text-align: center;
  color: #1a4e74;
  margin-top: 7rem;
  direction: rtl;
}
.similar_blog_post .hr {
  width: 50%;
  margin: auto;
}
.cross_container {
  display: block;
  text-align: right;
  margin-bottom: 1rem;
}
.cross_container img {
  width: 1.5rem;
  cursor: pointer;
}
/* Media Query */
@media only screen and (max-width: 576px) {
  .banner {
    height: 30rem;
  }
  .search_container {
    padding: 1rem 3rem;
  }
  .search_container input {
    font-size: 1.6rem;
  }
  .blog_post_card img {
    height: auto;
    aspect-ratio: 16 / 9;
  }
  .similar_blog_post .similar_title {
    font-size: 2.5rem;
    margin-top: 3rem;
  }
  .similar_blog_post .hr {
    width: 70%;
  }
  .similar_blog_post hr {
    width: 90%;
  }
  .blog_post_search_container {
    margin: 1rem 0 3rem;
  }
  .contact_container {
    padding: 2rem;
    margin: 5rem 0;
  }
  .contact_container p {
    font-size: 1.8rem;
  }
  .contact_container {
    flex-direction: column-reverse;
  }
  .menu hr {
    width: 70%;
  }
  .menu ul li {
    font-size: 1.6rem;
  }
  .menu ul li button {
    font-size: 1.6rem;
  }
}
