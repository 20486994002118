.modal_header {
  border-bottom: none;
}
.modal_content {
  border-radius: 2rem;
  overflow: hidden;
  /* padding: 2rem 3rem; */
}
.modal_title {
  font-size: 4.5rem;
  font-weight: 200;
  color: #1a4e74;
}
.modal_sub_title {
  font-size: 1.6rem;
  font-weight: 300;
  color: #000;
  direction: rtl;
}
.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
.modal_body {
  background: #f5fcff;
  padding: 2rem 3rem;
}
.modal_body h3 {
  font-weight: 600;
  font-size: 2rem;
  color: #1a4e74;
  direction: rtl;
}
.modal_body hr {
  color: #dcecf4;
  margin-top: 2rem;
}
.modal_body .title_hr {
  margin: auto;
  color: #1a4e74;
  margin-bottom: 1rem;
  width: 8rem;
}
.modal_body .cf_image {
  width: 10rem;
  height: 10rem;
  border: 0.5px solid #1a4e74;
  border-radius: 50%;
  cursor: pointer;
}
.modal_body .add_button {
  background: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  color: #1a4e74;
  padding: 0.1rem 2rem;
  border: 1px solid #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in;
  margin-top: 1rem;
}
.modal_body .add_button:hover {
  background: #1a4e74;
  color: #fff;
}

/* Dedicated Text */

.dedicated_texts input {
  color: #1a4e74;
  font-size: 2rem;
  font-weight: 200;
  width: 100%;
  border: 0px solid #d9d9d9;
  padding: 0.7rem 1.5rem;
  border-radius:1rem;
  text-align: center;
  direction: rtl;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.05);
  
}


.dedicated_texts input::placeholder {
  /*color: #cacaca;*/
  color: #1a4e74;
}
.counter {
  background-color: #fff;
  padding: 0.4rem 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.counter img {
  width: 3rem;
  height: 3rem;
  border: 0.5px solid #1a4e74;
  border-radius: 50%;
}
.counter span {
  font-size: 2rem;
  font-weight: 300;
  cursor: pointer;
  color: #1e272d;
}
.counter h5 {
  color: #1e272d;
  font-size: 2rem;
  font-weight: 300;
}
.increase_decrease button {
  border: 0;
  font-size: 1.6rem;
  color: #1a4e74;
  background: transparent;
  margin: 0 0.5rem;
  transition: 0.3s all ease;
}
.increase_decrease button:hover {
  transform: scale(1.5);
}

.btn_close_sm {
  border: none;
  margin-left: 1rem;
  background-color: #1a4e74;
  padding: 0.4rem;
  border-radius: 0.8rem;
  transition: 0.3s all ease-in;
}
.btn_close_sm:hover {
  transform: scale(1.05);
}
.btn_close_sm img {
  width: 3rem;
  height: 3rem;
}
.amount p {
  color: #1e272d;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 2rem;
  margin-bottom: 0;
}
.submit_btn {
  border-radius: 1rem;
  background: #1a4e74;
  height:5.8rem;
}
.amount_title {
  color: #1a4e74;
  font-size: 2rem;
  font-weight: 300;
  margin: 1rem 0;
  direction: rtl;
}
.submit_btn {
  width: 50%;
}


/*Ayelet*/

/*guestCFpurchase modal*/

:root {
  --bs-border-color-translucent: rgba(0, 0, 0, 0);
}

.guestCFModal {
  --bs-modal-width:69.5rem;
  --bs-modal-border-width: 0;
  
}

 .modal_dialog {
  width:69.5rem;
  max-width:70rem;
}

 .modal_content {
  border-radius:2rem;
  overflow:hidden;
  background-color: transparent;
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.1);
  
}

 .modal_header {
  background-color:#1a4e74;
  font-size:3.5rem;
  direction:rtl;
  text-align:center;
  height:12.2rem;
  display:flex;
  align-items:center;
}

 .header_title {
   font-size:3.5rem;
   color:#fff;
   font-weight:200;
}

 .modal_body {
  background:#fff;
  padding: 3rem 3rem;
}

 .modal_body span {
  font-size:15px;
  font-weight:400;
  color: #1A4E74;

}

.guestForm {
  width:30rem;
}

.rowGuest{
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom:6.7rem;
}

span.txt_title{
   font-size:2.5rem;
   color: #000;
   font-weight:200;
   margin-bottom:3.2rem;
}

.profilesContain {
  display:flex;
  justify-content:center;
  flex-direction:row-reverse;
  margin-bottom: 3.2rem;

}

.profileImage {
   width:6.7rem;
   height:6.7rem;
   overflow:hidden;
   border-radius: 50rem;
   border: 2px solid #fff;
   filter: drop-shadow(5px 1px 4px rgba(0, 0, 0, 0.15));
   margin-left: -15px;
   display:flex;
}

.profileImage  img {
  width:100%;
  height:100%;
  object-fit:cover;
  object-position:center;
}

.profileImageEmpty {
  background-color:  #1A4E74;
  color:#fff;
  align-items:center;
  justify-content:center;
  

}

.profileImageEmpty  span {
  color:#fff;
}

.likeBtn {
   box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.05);
   border-radius: 10px;
   height:4rem;
   padding:1rem;
   box-sizing:border-box;
   width:auto;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   direction: rtl;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    color: #BCBCBC;
}

.likeBtn span {
  color:#000;
  width: auto;
}

.socialIcons {
  width:auto;
  cursor:pointer;
}

.socialIcons img {
  height:25px;
  
}

.linkLogin span {
  cursor: pointer;
  font-size:1.5rem;
  color:#000;
  text-decoration:underline;
  direction:rtl;
}



/* Media Query */
@media only screen and (max-width: 768px) {
  .modal_title {
    font-size: 3.5rem;
  }
  .modal_sub_title {
    font-size: 1.4rem;
  }
  .modal_body {
    padding: 2rem 2rem 1rem;
  }
  .modal_body .cf_image {
    width: 6rem;
    height: 6rem;
  }
  .modal_body .add_button {
    font-size: 1.3rem;
    padding: 0.1rem 1.5rem;
  }
  .increase_decrease button {
    margin: 0;
  }
  .counter h5,
  .counter span {
    font-size: 1.6rem;
  }
  .submit_btn {
    width: 100%;
  }
  
  /*Ayelet*/
  
   .modal_dialog {
      width:100%;
      max-width:100%;
  }
  
  .guestForm {
    width:100%;
  }
  
  .header_title {
    font-size:3rem;
    padding:0 2rem;
  }
  
}
