/* Font-Family */
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700&display=swap');
@font-face {
  font-family: luizi;
  src: url('./assets/fonts/os_luizi-round-ffc.otf');
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 400;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (max-width: 576px) {
  body .accessibility_component {
    top: 85%;
    right: 5%;
  }
}
