.memory h2 {
  font-size: 4.5rem;
}

.memory .memory_details_img {
  position: relative;
}
.memory .memory_details_img img,
.memory .memory_details_img video {
  display: block;
  width: 60%;
  height: auto;
  margin: auto;
  object-fit: cover;
  border-radius: 3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.memory .memory_details .icons {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
}
.memory .memory_details .no_img_icon {
  position: static;
  transform: none;
}
.memory .memory_details .icons i {
  font-size: 2rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 50%;
  color: #1a4e74;
  cursor: pointer;
}
.memory .memory_details .icons span {
  font-size: 2rem;
}
.memory .memory_details p,
.memory .memory_description_a {
  text-align: center;
  font-size: 3rem;
  color: #6097bf;
  padding: 3.5rem 6rem;
}
.memory h3 {
  font-size: 4.5rem;
  font-weight: 300;
  text-align: center;
  color: #1a4e74;
  margin-bottom: 2rem;
}
.memory .pillbox {
  border: 2px solid #abc9db;
  margin-bottom: 2.5rem;
  text-align: right;
}
.pillbox p {
  white-space: pre-wrap;
}

.memory_comment {
  margin: 4rem 0 3rem;
}
.btn_group {
  margin-bottom: 4rem;
}
.memory .textarea {
  height: 10rem;
  border: 2px solid #6097bf;
  border-radius: 3rem;
  resize: none;
}
.memory_description {
  white-space: pre-wrap;
}
.memory_description a {
  color: #6097bf;
}

.memory .btn_comment {
  background-color: #1a4e74;
  padding: 3rem 0;
  font-weight: 300;
}
.memory .btn_comment:hover {
  background-color: transparent;
  color: #1a4e74;
}
.memory .btn_group {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.memory .btn_group button {
  background-color: #1a4e74;
  padding: 2rem 0;
  font-weight: 300;
}
.memory .btn_group button:hover {
  background-color: transparent;
  color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .memory h2 {
    font-size: 3rem;
  }
  .memory h3 {
    font-size: 3rem;
  }
  .memory .memory_details_img img,
  .memory .memory_details_img video {
    width: 100%;
  }
  .memory .btn_comment {
    padding: 2rem 0;
  }
  .memory .btn_group button {
    padding: 1.2rem 0;
  }
  .memory_comment {
    margin: 3rem 0 2rem;
  }
  .memory .memory_details p {
    font-size: 2.5rem;
    padding: 2.5rem 4rem;
  }
  .memory .memory_details .icons {
    gap: 1.8rem;
    bottom: 0;
  }
  .memory .memory_details .icons i {
    font-size: 1.8rem;
    padding: 0.8rem;
  }
  .share_button img {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.row_buttons button {
  width: 100%;
}
