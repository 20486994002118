.hero_search {
  background-color: #1a4e74;
  padding: 1.5rem 0;
}

.hero_search_body {
  width: 35rem;
  margin: 0 auto;
}
.hero_search_body .search_results {
  width: 35rem;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hero_search_body .search_icon {
  width: 2.4rem;

  position: absolute;
  right: 5rem;
}
.input {
  width: 100%;
  text-align: center;
  border-radius: 1rem;
  border: 1px solid #fff;
  font-size: 2rem;
  font-weight: 400;
  font-family: inherit;
  color: #abc9db;
}
/* .with_value {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
} */
.input::placeholder,
.input:focus {
  color: #1a4e74;
}

@media screen and (max-width: 768px) {
  .hero_search_body,
  .hero_search_body .search_results {
    width: 80%;
  }

  .hero_search {
    padding: 1rem 0;
  }
  .hero_search_body .search_icon {
    right: 10%;
    width: 2rem;
  }
  .input {
    font-size: 1.6rem;
  }
  .input::placeholder,
  .input:focus {
    font-size: 1.6rem;
  }
}
