.wall_friend h2 {
  font-size: 5.5rem;
  font-weight: 300;
  color: #46789b;
  text-align: center;
  margin-bottom: 3rem;
}
.wall_friend h3 {
  font-size: 3rem;
  font-weight: 300;
  color: #6097bf;
  text-align: center;
  margin-bottom: 1.2rem;
}

.wall_friend_list {
  border-radius: 3rem;
  padding: 1.2rem 2rem;
  /* margin-bottom: 3rem; */
}
.wall_friend_list:not(:last-child) {
  margin-bottom: 2rem;
}

.wall_friend_list img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}
.wall_friend_list h3 {
  font-size: 2rem;
}
.wall_friend_list .action_button {
  border: 0;
  font-size: 1.6rem;
  padding: 0 0.8rem;
  background-color: transparent;
  color: #fff;
  font-weight: 600 !important;
}
.wall_friend_list .fancy_bar {
  font-size: 2rem;
}

.profileFriends {
  background: #dcecf4;
}

.profileFriends .action_button,
.profileFriends .fancy_bar {
  color: #1e272d;
}
.friendRequests {
  background: #c9e9f9;
}
.friendRequests h3,
.profileFriends h3 {
  color: #1a4e74;
  font-weight: 400;
  margin: 0;
}
.friendRequests .action_button,
.friendRequests .fancy_bar {
  color: #1e272d;
}
.profileAdmins {
  background: #1a4e74;
}
.profileAdmins .action_button {
  color: #fff;
}
.profileAdmins h3 {
  color: #fff;
  margin: 0;
}
.nav_arrow {
  cursor: pointer;
}
.nav_arrow h5 {
  font-size: 2.5rem;
  color: #6097bf;
}
.nav_arrow img {
  width: auto;
  height: 3rem;
}

@media screen and (max-width: 768px) {
  .wall_friend_list img {
    width: 5rem;
    height: 5rem;
  }
  .wall_friend_list h3 {
    font-size: 1.8rem;
  }
  .wall_friend_list .action_button {
    font-size: 1.4rem;
    padding: 0 0.3rem;
  }
  .wall_friend_list .fancy_bar {
    font-size: 1.8rem;
  }
}
