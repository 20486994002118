.normal_profile_description {
  padding: 5rem 0;
}
.organization_profile_description {
  padding-top: 0;
  padding-bottom: 5rem;
}
.profile_description p {
  color: #6097bf;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.5;
  white-space: pre-wrap;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .organization_profile_description {
    padding-top: 0;
    padding-bottom: 3rem;
  }
  .normal_profile_description {
    padding: 2rem 0;
  }
  .profile_description h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .profile_description p {
    font-size: 1.8rem;
    color: #6097bf;
    line-height: 1.5;
  }
}
.profile_description .see_more {
  display: inline-block;
  margin-right: 0.8rem;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.6rem;
}
