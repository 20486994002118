.wrapper {
  background: #fff;
  padding: 0.8rem 1.6rem;
  border-radius: 3rem;
  gap: 1rem;
}

.img_container img {
  display: block;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.text_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.text_container span,
.text_container p,
.text_container .bar {
  margin: 0;
  font-weight: 400;
  font-size: 2rem;
  color: #abc9db;
}

.text_container .bar {
  margin: 0 0.8rem;
}

.text_container .event {
  color: #6097bf;
  margin-right: 0.8rem;
}

.pill_button {
  background: #6097bf;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.pill_button:hover {
  background: #1a4e74;
}

.additional_img_container img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid;
  cursor: pointer; /* Change cursor to indicate it's clickable */
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal_image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0.6rem 1.2rem;
    border-radius: 2rem;
  }

  .img_container img {
    display: block;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }

  .text_container span,
  .text_container p,
  .text_container .bar {
    font-size: 1.6rem;
  }
}
