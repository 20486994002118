@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700&display=swap');

    *,
    *::after,
    *::before {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    /*body {*/

    /*}*/

    /*p,*/
    /*h1,*/
    /*h2,*/
    /*h3,*/
    /*h4,*/
    /*h5,*/
    /*h6 {*/
    /*  margin: 0;*/
    /*}*/

    .footer {
      background: #1A4E74;
      padding: 45px 0 30px;
        margin: 0;
      font-family: 'Assistant', sans-serif;
      font-weight: 300;
    }

    .footer hr {
      border: 0.5px solid #fff;
      margin: 50px 0 30px;
    }

    .upperFooter h4 {
      font-size: 25px;
      font-weight: 400;
      color: #d9d9d9;
      margin-bottom: 15px;
    }

    .upperFooter ul {
      list-style-type: none;
      padding-left: 0;
    }

    .upperFooter .contactInfo input {
      text-align: right;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      padding: 3px 15px;
    }

    .upperFooter .contactInfo input::placeholder {
      color: #1A4E74;
      font-size: 13px;
    }

    .upperFooter .contactInfo button {
      background: #1A4E74;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 2px;
      font-size: 12px;
      color: #fff;
      font-weight: 400;
      align-self: self-end;
      padding: 4px 15px;
      direction: rtl;
      transition: all 0.3s ease-in;
    }

    .upperFooter .contactInfo button:hover {
      background: #fff;
      color: #1A4E74;
    }

    .socialMedia {
      cursor: pointer;
    }

    .upperFooter ul li a {
      font-size: 17px;
      display: block;
      color: #d9d9d9;
      text-decoration: none;
    }

    .lowerFooter p {
      font-size: 17px;
      color: #fff;
      margin-top: 5px;
    }

    .maxContent {
      width: max-content;
      margin: auto;
      text-align: right;
    }

    /* Media Query */
    @media only screen and (max-width: 768px) {
      .inputContainer {
        width: 100%;
      }

      .maxContent {
        width: 100%;
      }

      .upperFooter h4 {
        font-size: 20px;

      }

      .upperFooter ul li a {
        font-size: 14px;

      }

      .socialMedia img {
        width: 30px;
      }

      .upperFooter .contactInfo button {
        width: 100%;
      }

      .footer hr {
        border: 0.25px solid #fff;
      }

      .lowerFooter img {
        width: 120px;
      }

      .lowerFooter p {
        font-size: 14px;

      }

    }