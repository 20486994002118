.memorial .hr {
  width: 30%;
  margin: auto;
  color: #fff;
  margin-bottom: 3.5rem;
}

.memorial_content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 5rem;
}
.memorial_content p {
  direction: rtl;
}
.memorial_content p,
.memorial_content span {
  font-size: 2.2rem;
  font-weight: 200;
  color: #1a4e74;
}
.memorial_action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3.5rem;
}
/*  */
.memorial a {
  background: #fff;
  padding: 0.5rem 2.5rem;
  display: inline-block;
  border: none;
  border-radius: 1rem;
  transition: all 0.3s ease-in;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.memorial a:hover {
  background: #eaf4f8;
}
.memorial button {
  background: #fff;
  padding: 0.8rem 2.5rem;
  display: inline-block;
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
  color: #1a4e74;
  transition: 0.3s all ease-in;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.memorial button:hover {
  color: #fff;
  background: #1a4e74;
}
.memorial .img {
  width: 3.5rem;
  height: 3.5rem;
}
@media only screen and (max-width: 768px) {
  .memorial {
    padding: 2rem 0;
  }
  .memorial h2 {
    font-size: 3rem;
  }
  .memorial_content p {
    font-size: 1.8rem;
  }
  .memorial hr,
  .memorial .hr {
    width: 90%;
    color: #fff;
    margin: auto;
  }
  .memorial .hr {
    margin-top: 1.5rem;
  }
  .memorial hr {
    margin-top: 1rem;
  }
  .memorial button {
    font-size: 1.6rem;
  }
  .memorial_content {
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: 0rem 1rem;
  }
  .memorial_action {
    gap: 0 1rem;
    margin-top: 2rem;
  }
  .memorial a,
  .memorial button {
    border-radius: 0.5rem;
    position: relative;
    z-index: 2;
  }
  .memorial .img {
    width: 3rem;
    height: 3rem;
  }
}
/* @media only screen and (max-width: 576px) {
  .memorial_content {
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: 0rem 1rem;
  }
} */
