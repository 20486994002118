/* Dedication Container styling */
.dedication_img {
  text-align: center;
}
.dedication_container {
  text-align: center;
  height: 30rem;
  overflow-y: auto;
}
/* ScrollBar Styling */
.dedication_container::-webkit-scrollbar {
  width: 0.3rem;
}

.dedication_container::-webkit-scrollbar-track {
  background: #dcecf4;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset;
}

.dedication_container::-webkit-scrollbar-thumb {
  background: #6097bf;
  border-radius: 2rem;
  background-clip: content-box;
  transition: all 0.3 ease-in;
}
.dedication_text {
  font-size: 2rem;
  font-weight: 200;
  color: #1a4e74;
  direction: rtl;
  margin: 1.5rem 0;
}
.hr {
  width: 50%;
  margin: auto;
  color: #1a4e74;
}
.dedicator {
  font-size: 1.5rem;
  font-weight: 200;
  color: #1a4e74;
  direction: rtl;
  margin-top: 0.5rem;
}
.comments_number {
  font-size: 1.3rem;
  font-weight: 200;
  color: #1a4e74;
  direction: rtl;
  margin-top: 3rem;
}

.commenter_name {
  font-size: 1.5rem;
  font-weight: 200;
  color: #6097bf;
  direction: rtl;
  margin: 0;
}
.commenter_content {
  font-size: 1.7rem;
  font-weight: 200;
  color: #1a4e74;
  direction: rtl;
  margin-bottom: 1.2rem;
}
.commenter_img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.dedication_btn {
  padding: 1rem 2.5rem;
  background: #f5fcff;
  border-radius: 2rem;
  color: #1a4e74;
  font-size: 1.5rem;
  font-weight: 300;
  border: 1px solid #f5fcff;
  margin-top: 2rem;
  transition: 0.3s all ease-in;
  direction: rtl;
}
.dedication_btn:hover {
  background: #1a4e74;
  color: #fff;
}

.btn_close img {
  width: 1.6rem;
  height: 1.4rem;
}
