.footer {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  padding: 5rem 0;
}

.footer img {
  cursor: pointer;
  width: auto;
  max-height: 3rem;
  height: auto;
}

.footer_links a {
  transition: ease-in-out 1s;
}
.footer_links a:hover {
  color: #dcecf4;
}
.line_separator {
  margin: 0 1.5rem;
  color: #1a4e74;
}

.footer a {
  text-decoration: none;
  color: #6097bf;
}
.footer_links {
  font-size: 2rem;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.c_rights_reserved {
  font-size: 2rem;
  color: #1a4e74;
  margin-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .footer img {
    max-height: 4rem;
  }
}
@media screen and (max-width: 992px) {
  .footer img {
    margin-bottom: 3rem;
  }
  .c_rights_reserved {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .footer img {
    max-height: 3rem;
  }
  .footer_links {
    font-size: 1.6rem;
  }

  .c_rights_reserved {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
  .line_separator {
    margin: 0 1rem;
  }
}
@media screen and (max-width: 576px) {
  .line_separator_sm {
    opacity: 0;
  }
}
@media screen and (max-width: 1399px) {
  .fifth {
    visibility: hidden;
  }
}
@media screen and (max-width: 1199px) {
  .fifth {
    visibility: visible;
  }
  .fourth {
    visibility: hidden;
  }
}
@media screen and (max-width: 991px) {
  .fourth {
    visibility: visible;
  }
}

@media screen and (max-width: 496px) {
  .fourth {
    visibility: visible;
  }
  .fifth {
    visibility: hidden;
  }
}
@media screen and (max-width: 405px) {
  .fifth {
    visibility: visible;
  }
  .fourth {
    visibility: hidden;
  }
}
@media screen and (max-width: 333px) {
  .third {
    visibility: hidden;
  }
  .fourth {
    visibility: visible;
  }
}
