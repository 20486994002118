.memory {
  background-image: url('https://images.unsplash.com/photo-1661794465928-22538f665d87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1456&q=80');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0;
  border-radius: 3rem;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.memory:not(:last-child) {
  margin-bottom: 3rem;
}
.memory_video {
  padding: 1.92rem 0;
}

.social_icons_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.memory_video .social_icons_container {
  gap: 0.8rem;
}
.social_icon {
  background-color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.social_icon i {
  font-size: 1rem;
  color: #6097bf;
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 2rem 0 0 2rem;
  padding: 0.4rem 0.5rem;
}
.info p {
  font-size: 1.2rem;
  color: #6097bf;
}
.info span {
  margin: 0 0.6rem;
}
.info h3 {
  color: #1a4e74;
  font-size: 1.6rem;
  margin-bottom: 0;
}
