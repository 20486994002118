.payment_status_title {
  font-size: 5rem;
  color: #1a4e74;
  margin-bottom: 5rem;
  line-height: 1.5;
  font-weight: 300;
}
.payment_status_button {
  background-color: #70a4cb;
  font-weight: 300;
  color: #fff;
  font-size: 2.5rem;
  padding: 1rem 6rem;
}
.payment_status_button_second {
  background-color: #1a4e74;
}
@media screen and (max-width: 768px) {
  .payment_status_title {
    font-size: 3.6rem;
  }
  .payment_status_button {
    font-size: 2rem;
    padding: 0.8rem 4rem;
  }
}
